import  React from 'react';
import 'bootstrap-v4-rtl/dist/js/bootstrap.bundle.min';
import { URL_DASHBOARD} from "../config";
import {getSetting} from "../sotre/actions/settingAction";
import {connect} from 'react-redux'
import moment from 'moment-timezone'
import {Link} from "react-router-dom";


class  Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doc: null,
            avis:null,
            concours:null,
            email:null,
            phone:null,
            grief: null,
            timestamp:null,
            doc_juri:null,
            concours_inscrit:null,
            date_fininscrit:null,
            is_closed:false,
            is_blocked:false,
            setting:null,
            shares:null

        }
    }

    useEffect =(setting)=>{
        const interval = setInterval(() => {
          this.getDateDiff(setting);
        }, 1000);
        return () => clearInterval(interval);
    }
    getDateDiff(setting) {

                var table=setting.concours.dated.split(' ');
                var datetime=table[0].split('-');
                var time=table[1].split(':')
                // console.log(datetime[0]+','+datetime[1]+','+datetime[2]+','+time[0]+','+time[1]+','+time[2]);
                var date_debut=new  Date(datetime[0],datetime[1]-1,datetime[2],time[0],time[1],time[2]);
                var date1=new Date();
                // console.log(date_debut+'***'+date1);
                if(date1<=date_debut){
                    this.setState({is_blocked: true});
                    var sec = (date_debut - date1) / 1000;
                     // console.log('Sec:='+sec);
                    var n = 24 * 3600;
                    if (sec >=1) {
                        var j = Math.floor(sec / n);
                        var h = Math.floor((sec - (j * n)) / 3600);
                        var mn = Math.floor((sec - ((j * n + h * 3600))) / 60);
                        var sec2 = Math.floor(sec - ((j * n + h * 3600 + mn * 60)));
                        var text = '';
                        if (j > 1)
                            text = j + '(أيام) ';
                        if (j == 0)
                            text = '';
                        if (j == 1)
                            text = 'يوم ';
                        if (h > 0)
                            text += ' ' + h + ' (ساعة) ';
                        if (mn > 0)
                            text += ' ' + mn + ' (دقيقة)';
                        text += ' ' + sec2 + '( ثانية ) ';

                        this.setState({timestamp: text});
                    }
                }
                else{
                    this.setState({is_blocked: false});
                    var table=setting.concours_inscrit.date_fininscription.split(' ');
                    var datetime=table[0].split('-');
                    var time=table[1].split(':')
                    // console.log(datetime[0]+','+datetime[1]+','+datetime[2]+','+time[0]+','+time[1]+','+time[2]);
                    var date2=new  Date(datetime[0],datetime[1]-1,datetime[2],time[0],time[1],time[2]);
                    // console.log(date2);
                    var sec = (date2.getTime() - date1.getTime()) / 1000;

                    // console.log('Sec:='+sec);
                    var n = 24 * 3600;
                    if (sec >=1) {
                        var j = Math.floor(sec / n);
                        var  h = Math.floor((sec - (j * n)) / 3600);
                        var   mn = Math.floor((sec - ((j * n + h * 3600))) / 60);
                        var   sec2 = Math.floor(sec - ((j * n + h * 3600 + mn * 60)));
                        var text='';
                        if(j>1)
                            text=j+'(أيام) ';
                        if(j==0)
                            text='';
                        if(j==1)
                            text= 'يوم ';
                        if(h>0)
                            text+=' '+h+' (ساعة) ';
                        if(mn>0)
                            text+=' '+mn+' (دقيقة)';
                        text+=' '+sec2+'( ثانية ) ';

                        this.setState({timestamp: text});

                    }else{
                        this.props.setting.is_closed=true;
                        this.setState({is_closed:true})

                    }
                }


    }
    getLastConcours=() =>{
        try {


                const {setting} = this.props.setting;
                this.setState({setting:setting});
                this.setState({email: setting.email,phone: setting.phone,is_closed:setting.is_closed});

                if (setting.concours) {
                    this.setState({concours: setting.concours});
                    if(setting.concours_inscrit)
                        this.setState({concours_inscrit:setting.concours_inscrit});
                    if(setting.grief)
                        this.setState({grief: setting.grief});
                    if(setting.avis)
                        this.setState({avis: setting.avis});
                    if(setting.doc_jur)
                        this.setState({doc_juri: setting.doc_jur});
                }
                if (setting.concours_inscrit){
                    this.setState({concours_inscrit: setting.concours_inscrit});
                    this.getDateDiff(setting);
                    this.useEffect(setting);
                }
                if(setting.shares && setting.shares.length>0)
                    this.setState({shares: setting.shares});





        } catch (err) {

            console.error(err);
        }
    }
    componentDidMount() {
        moment.tz.setDefault("Africa/Tunis");
        this.props.getSetting();

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.setting){
            this.getLastConcours();
        }

    }
    componentWillUnmount() {
    }

    render() {
        return (

            <footer className=" text-center container" >
                <div className="col-lg-8 mx-auto bg-light">

                    <div>
                        {this.state.concours   && !this.state.is_closed && !this.state.is_blocked &&
                        <div>
                            <hr />
                            <p> الوقت المتبقي للتسجيل في المناظرة :{this.state.timestamp}</p>
                        </div>

                    }
                        { this.state.is_blocked  &&
                        <div>
                            <hr />
                            <p> الوقت المتبقي لفتح المناظرة :{this.state.timestamp}</p>
                        </div>

                        }
                        {this.state.is_closed &&
                        <div>
                            <hr />
                            <p>إنتهى وقت التسجيل في المناظرة</p>
                        </div>

                        }


                    </div>

                    <hr />
                    <div className="row text-justify">
                        <div className="col-md-6" >
                            {this.state.concours && !this.state.is_blocked &&
                            <div style={{display:"grid"}}>
                                {this.state.avis &&
                                <Link to={"/pdf/"+this.state.avis} target="_blank" className="navbar-dark">
                                    <i className="fa fa-hand-o-left"></i>&ensp;&ensp;&ensp;
                                    الإطلاع على بلاغ المناظرة
                                </Link>

                                }
                                <br />
                                {this.state.doc_juri &&
                                <Link to={"/pdf/"+this.state.doc_juri} target="_blank" className="navbar-dark">
                                    <i className="fa fa-hand-o-left"></i>&ensp;&ensp;&ensp;
                                    الإطلاع على النصوص القانونية
                                </Link>

                                }
                                <br />
                                {this.state.grief &&
                                <Link to={"/pdf/"+this.state.grief} target="_blank" className="navbar-dark">
                                    <i className="fa fa-hand-o-left"></i>&ensp;&ensp;&ensp;
                                    طباعة مطلب تظلم
                                </Link>
                               
                                }
                                {this.state.shares &&
                                <Link to={"/suivieconcours2"}  className="navbar-dark">
                                    <i className="fa fa-hand-o-left"></i>&ensp;&ensp;&ensp;
                                    الإطلاع على مراحل تقدم المناظرة
                                </Link>

                                }
                            </div>

                            }
                        </div>
                        <div className="col-md-6">
                            <span className="text-info"> العنوان الإلكتروني </span>:
                            <span className="text-muted">
                              <a href={"mailto:" + this.state.email}> {this.state.email}</a>
                            </span>
                            <div className="col-md-12">
                                <span className="text-info"> الهاتف  </span>:
                                <span className="text-muted"   style={{textAlign:"left",direction:"ltr"}}> {this.state.phone}</span>
                            </div>
                        </div>


                    </div>

                    <hr/>

                </div>
            </footer>
        );
    }
}

const mapStateToProps  = (state) => ({setting:state.setting})

export default connect(mapStateToProps, {getSetting})(Footer)
