import Header from "./Compenents/Header";
import Footer from "./Compenents/Footer";
import {BrowserRouter as Router, Route} from "react-router-dom";
import React from "react";
import DetailSujet from "./Compenents/DetailSujet";
import Register from "./Compenents/Register";
import CheckRegister from "./Compenents/CheckRegister";
import Dashboard from "./Dashboard/Dashboard";
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css';
// import 'bootstrap-v4-rtl/bundle/bootstrap.bundle';
import Formulairecondidature from "./Dashboard/Formulairecondidature";
import Changepassword from "./Compenents/Changepassword";
import Auth from "./Compenents/Auth";
import Pdfview from "./Dashboard/Pdfview";
import Documentprint from "./Dashboard/Documentprint";
import Suivieconcours from "./Dashboard/Suivieconcours";
import Suivieconcours2 from "./Compenents/Suivieconcours2";


function App() {

    return (

            <Router>

                <Route exact path="/">
                    <div className="App">
                        <main>
                            <Header />
                            <Auth />
                        </main>
                        <Footer />

                    </div>
                </Route>
                <Route exact path="/register-condidate/:slug/:title" render={(props) => <Formulairecondidature {...props} /> } />
                <Route path="/changepassword">
                    <div className="App">
                        <main>
                            <Header />
                            <Changepassword />
                        </main>
                        <Footer />

                    </div>
                </Route>
                <Route path="/register">
                    <div className="App">
                        <main>
                            <Header />
                            <Register />
                        </main>
                        <Footer />

                    </div>
                </Route>
                <Route path="/dashboard"  render={(props) => <Dashboard name="dashboard" {...props} />} />
                <Route path="/suivieconcours"  render={(props) => <Suivieconcours name="suivieconcours" {...props} />} />
                <Route path="/suivieconcours2">
                    <div className="App">
                        <main>
                            <Header />
                            <Suivieconcours2 />
                        </main>
                        <Footer />

                    </div>
                </Route>
                <Route path="/document"  render={(props) => <Documentprint name="document" {...props} />} />

                <Route path="/check-register/:cin" render={(props) => <CheckRegister {...props} /> } />

                <Route exact path="/detail/:slug" render={(props) => <DetailSujet {...props} /> } />

                <Route exact path="/pdf/:viewpdf" render={(props) => <Pdfview {...props} /> } />

            </Router>



  );
}


export default App;
