import React from 'react';
import {Link} from "react-router-dom";
import {API_BASE_URL, URL_DASHBOARD} from "../config";
import axios from "axios";
import {connect} from "react-redux";
import {getSetting} from "../sotre/actions/settingAction";

class Navph extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            slug:null,
            email:null,
            phone:null,
            grief: null,
            timestamp:null,
            doc_juri:null,
            setting:null,
            shares:null
        }

    }

    getLastConcours=() =>{
        try {
            const {setting} = this.props.setting;
            this.setState({setting:setting});
            this.setState({email: setting.email,phone: setting.phone,is_closed:setting.is_closed});

            //if (setting.concours) {

               // if(setting){
                    if(setting.grief)
                    this.setState({grief: setting.grief});
                     if(setting.avis)
                        this.setState({avis: setting.avis});
                     if(setting.doc_jur)
                         this.setState({doc_juri:setting.doc_jur});
                     if(setting.shares && setting.shares.length>0)
                         this.setState({shares: JSON.stringify(setting.shares)});
                    // }
              //  }

        } catch (err) {

            console.error(err);
        }
    }
    componentDidMount() {
        this.props.getSetting();
        if(sessionStorage.getItem('is_connected')){
            this.setState({is_connected:sessionStorage.getItem('is_connected')});
             this.setState({user:JSON.parse(localStorage.getItem('user'))});
             if(localStorage.getItem('slug'))
                this.setState({slug:localStorage.getItem('slug')});
        }
        this.getLastConcours();
    }
    componentWillUnmount() {
        this.getLastConcours();
    }

    render() {
        return(
            <nav id="sidebarMenu"
                 className="col-md-3 collapse col-lg-2 d-md-block bg-light sidebar sticky-top">
                <div className="position-sticky pt-3">

                    <ul className="nav flex-column font-weight-bold">
                        <li className="nav-item border-bottom">
                            {this.props.name === 'dashboard' &&
                            <Link to="/dashboard" className="nav-link active" aria-current="page" href="#">
                                <span className="fa fa-home  margin">&ensp;</span>
                                حساب المترشح
                            </Link>
                            }
                            {this.props.name !== 'dashboard' &&
                            <Link to="/dashboard" className="nav-link text-muted" aria-current="page" href="#">
                                <span className="fa fa-home  margin">&ensp;</span>
                                حساب المترشح
                            </Link>
                            }
                        </li>
                        <li className="nav-item border-bottom">
                            {this.props.name === 'document' &&
                            <Link to="/document" className="nav-link active" aria-current="page" href="#">
                                <span className="fa fa-print margin">&ensp;</span>
                                طباعة إستمارة الترشح
                            </Link>
                            }
                            {this.props.name !== 'document' &&
                            <Link to="/document" className="nav-link text-muted" aria-current="page" href="#">
                                <span className="fa fa-print  margin">&ensp;</span>
                                طباعة إستمارة الترشح
                            </Link>
                            }
                        </li>
                        <li className="nav-item border-bottom">

                            <Link to="/changepassword" className="nav-link text-muted" aria-current="page" href="#">
                                <span className="fa fa-key margin">&ensp;</span>
                                تغير كلمة العبور
                            </Link>

                        </li>


                            {this.state.avis &&
                            <li className="nav-item border-bottom">
                                <Link to={"/pdf/"+this.state.avis} target="_blank" className="nav-link text-muted">
                                    <i className="fa fa-hand-o-left"></i>&ensp;
                                    الإطلاع على بلاغ المناظرة
                                </Link>
                            </li>
                            }
                            {this.state.doc_juri &&
                            <li className="nav-item border-bottom">
                                <Link to={"/pdf/"+this.state.doc_juri} target="_blank" className="nav-link text-muted">
                                    <i className="fa fa-hand-o-left"></i>&ensp;
                                    الإطلاع على النصوص القانونية
                                </Link>
                            </li>
                            }
                        {this.state.shares && this.state.shares.length>0 && this.props.name === 'suivieconcours' &&
                                    <li className="nav-item border-bottom">
                                        <Link to={"/suivieconcours"} className="nav-link active">
                                            <i className="fa fa-hand-o-left"></i>&ensp;
                                            الإطلاع على مراحل تقدم المناظرة
                                        </Link>
                                    </li>

                        }
                        {this.state.shares  && this.state.shares.length>0 && this.props.name != 'suivieconcours' &&
                        <li className="nav-item border-bottom">
                            <Link to={"/suivieconcours"} className="nav-link text-muted">
                                <i className="fa fa-hand-o-left"></i>&ensp;
                                الإطلاع على مراحل تقدم المناظرة
                            </Link>
                        </li>

                        }
                            {this.state.grief &&
                            <li className="nav-item border-bottom">
                                <Link to={"/pdf/"+this.state.grief} target="_blank" className="nav-link text-muted">
                                    <i className="fa fa-hand-o-left"></i>&ensp;
                                    طباعة مطلب تظلم
                                </Link>
                            </li>
                            }
                    </ul>

                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span style={{borderBottom:"solid 1px black"}}>للإتصال بنا</span>
                    </h6>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span style={{borderBottom:"solid 1px black"}} >
                             <a href={"mailto:" + this.state.email}> {this.state.email}</a>

                        </span>

                    </h6>
                    <h6 dir="ltr" className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span style={{borderBottom:"solid 1px black",fontSize:"15px",direction:"rtl"}}>
                          {this.state.phone}
                        </span>
                    </h6>
                    <hr />
                </div>
            </nav>
        )
    }
}
const mapStateToProps  = (state) => ({setting:state.setting})

export default connect(mapStateToProps, {getSetting})(Navph)
