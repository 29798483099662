import React from 'react';
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css';
import '../App.css';
import {Link} from "react-router-dom";
class Header extends React.Component {
    render() {
        return (
            <section className="text-center container">
                <div className="row ">

                    <div className="mx-auto col-md-6 ">
                        <LinkHome to="/" logo={window.location.origin+'/logo.png'}>

                        </LinkHome>
                        <h3 className="font-weight-light">  الصيدلية المركزية التونسية</h3>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mx-auto title bg-info text-white">
                        <h3 className="text-center">موقع التسجيل بالمناظرة الخارجية</h3>
                    </div>

                </div>
                <br />
            </section>
        );
    }
}

function LinkHome({to,logo}) {
    return (

            <Link to={to}>
                <img src={logo}  alt=" الصيدلية المركزية التونسية" className="App-logo" />
            </Link>


    );
}
export default Header;
