import React, {useState} from 'react';
import Navph from "./Navph";
import Alert from "react-bootstrap/Alert";
import {Link, useHistory} from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import $ from 'jquery';
import DatePicker,{range} from 'react-datepicker';
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ar from 'date-fns/locale/ar-SA';
import Topdashboard from "./Topdashboard";
import Headerdashboard from "./Headerdashboard";
import Printdocument from "./Printdocument";
registerLocale('ar', ar);
class Formulairecondidature extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            msg_remove: null,
            slug: null,
            title: null,
            sujet:null,
            champs:null,
            diplomes:null,
            villes:null,
            isLoading:null,
            deligations:null,
            codespostals:null,
        }

    }
    loadValue=()=>{

        if(localStorage.getItem('user')){
            let user=JSON.parse(localStorage.getItem('user'));
            if(user.gendre)
            {
                if( user.gendre==='ذكر'){
                    $('#male').prop('checked',true);
                }else{
                    $('#female').prop('checked',true);
                }
            }
            if(user.cituation){

               $('#cituation option:selected').text(user.cituation);
            }
            if(user.adresse){
                $('#adr').text(user.adresse);
            }
            if(user.id_ville){
                if(!this.state.deligations)
                    this.AllDeligation(user.id_ville);

            }
            if(user.id_deligation){
                this.AllCodepostal(user.id_deligation);

            }
            if(user.id_code){
                $('#codepostal').val(user.id_code);
            }




        }
    }
    async AllVille() {



            try {
                this.setState({ isLoading: true });
                const response = await fetch(API_BASE_URL + '/allville', {});
                const villeList = await response.json();
                this.setState({ villes: villeList.data, isLoading: false});

            } catch (err) {
                this.setState({ isLoading: false });
                console.error(err);
            }



    }
    async AllDeligation(id_ville){
        if(id_ville){

            try {
                axios.get(API_BASE_URL+ '/deligation/'+id_ville)
                    .then((result) => {
                        this.setState({deligations:JSON.parse(JSON.stringify(result.data.data))});
                    });

            } catch (err) {

                console.error(err);
            }
        }
    }
    async AllCodepostal(id_deligation){
        if(id_deligation && !this.state.codespostals){

            try {
                axios.get(API_BASE_URL+ '/codepostal/'+id_deligation)
                    .then((result) => {
                        this.setState({codespostals:JSON.parse(JSON.stringify(result.data.data))});
                    });

            } catch (err) {

                console.error(err);
            }
        }
    }
    async getDetailSujets(slug) {

        if (! this.state.sujet) {
            var bodyFormData = new FormData();
            bodyFormData.append('slug', slug);
            bodyFormData.append('token',sessionStorage.getItem('access_token'));
            let axiosConfig = {
                headers: { 'Content-Type': 'multipart/form-data' }
            };
           await axios.post(API_BASE_URL+'/detail', bodyFormData,axiosConfig)
                .then((result) => {
                    if(result.data ) {
                        this.setState({sujet:result.data.sujet});
                        this.setState({champs:result.data.champs});
                        this.setState({diplomes:result.data.diplomes});
                            result.data.champs.forEach(champ =>{
                               var listvalue=result.data.listvalue;
                               listvalue.forEach(list=>{
                                   if(list.id===champ.id){
                                       if(champ.type==="Plusieurs valeurs"){
                                            $('#'+champ.idname).val(list.value);
                                       }
                                       else{
                                                if( champ.type!='Date'){
                                                    $('#'+champ.idname).val(list.value);

                                                }else{
                                                    if(!localStorage.getItem(champ.idname)){
                                                        $('#'+champ.idname).val(list.value);
                                                        localStorage.setItem(champ.idname,list.value);
                                                    }

                                                }

                                       }
                                   }

                               });
                            });
                        }




                });

        }
    }

    componentDidMount() {
        this.setState({slug:this.props.match.params.slug,title:this.props.match.params.title});
        if(sessionStorage.getItem('is_connected')){

            this.setState({is_connected:sessionStorage.getItem('is_connected')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});
            this.AllVille();
            this.loadValue();
            this.getDetailSujets(this.props.match.params.slug);
        }


    }
    render() {
        return(
            <div className="App">

                <Headerdashboard />

                <div className="container-fluid">
                    <div className="row">

                        <Navph name="dashboard" />

                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                            <div className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2 text-danger">تعمير إستمارة الترشح:  {this.state.title}</h1>

                                </div>
                                <div className="row">
                                    <div className="col-md-6  bg-light border title">

                                       <div className="row col-md-12">
                                                <Defaultformulaire codespostals={this.state.codespostals} deligations={this.state.deligations} diplomes={this.state.diplomes} villes={this.state.villes} slug={this.state.slug} champs={this.state.champs} />
                                            </div>
                                    </div>
                                    <div className="col-md-5  title">
                                        {!this.state.slug &&
                                        <Alert
                                            variant="info" onClose={() => localStorage.getItem('is_connected')} >
                                            <Alert.Heading></Alert.Heading>

                                            <p className="font-italic">
                                                لم يتم إختيار أي خطة حتى الآن
                                            </p>

                                        </Alert>}
                                        {this.state.slug &&
                                        <div>
                                            <Printdocument />
                                            <Alert
                                                variant="success" onClose={() => localStorage.getItem('is_connected')} >
                                                <Alert.Heading></Alert.Heading>

                                                <p className="font-italic">
                                                    الخطة :  {this.state.slug}
                                                </p>
                                                {this.state.user.score && this.state.user.score>0 &&
                                                <p className="font-italic">
                                                    العدد المسند للملف  :  {this.state.user.score}
                                                </p>
                                                }
                                                {this.state.user.id_titre &&
                                                <Link to={"/register-condidate/"+this.state.code+"/"+this.state.title}  >
                                                    الإطلاع أو تحين
                                                </Link>
                                                }


                                            </Alert>

                                        </div>
                                        }
                                        <Topdashboard />
                                    </div>

                                </div>
                                <br />



                            </div>
                            {/*<Footer />*/}
                        </main>

                    </div>
                    </div>

            </div>
        );
    }
}

class Forminput extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            champ: null,
            diplomes: null,
        }

    }
    getDiplome = ()=> {
        this.setState({champ:this.props.componentData});
        if(this.props.componentData.type==='Plusieurs valeurs'){
            let diplomes= this.props.componentData.value.split(';');
            let html='<option></option>';
            for(var k=0;k<diplomes.length;k++){
                let valeurchamp=diplomes[k].split(':=');
                html+='<option>'+valeurchamp[0]+'</option>';
            }

            this.setState({diplomes: html });

        }


    }

    componentDidMount() {

             this.getDiplome();
            // console.log(this.state.diplomes);
        }
        render() {
            if(this.props.componentData.type==='Plusieurs valeurs')
            return(
                <div className="col-md-12">
                    <label className="text-info">{this.props.componentData.name}</label>

                    <select id={this.props.componentData.idname} className="form-control custom-select-lg" dangerouslySetInnerHTML={{ __html: this.state.diplomes }}>

                    </select>
                    <small className="text-danger" id={"msg_"+this.props.componentData.idname}></small>
                </div>
            );
           else
            if(this.props.componentData.type==='Date')
                return(
                    <div className="col-md-6" id={"input_"+this.props.componentData.idname}>
                        <label className="text-info">{this.props.componentData.name}</label>

                        <Customdate2 name={this.props.componentData.idname} id={this.props.componentData.idname} />
                        <small className="text-danger" id={"msg_"+this.props.componentData.idname}></small>
                    </div>
                );
            else
                return(

                            <Inputnumeric name={this.props.componentData.name} id={this.props.componentData.idname} />

                );
        }
}
function Inputnumeric(props) {


    const [data, setdata] = useState({msg:null});
    const onChange = (e) => {
        e.preventDefault();
      if(e.target.validity.valid ){
          if( e.target.value<20){
              $('#'+e.target.name).val(e.target.value);
              setdata({...data,msg:null});
          }else{
              $('#'+e.target.name).val('');
              setdata({...data,msg:"الرجاء إدخال المعدل صحيح،لا يوجد معدل أكثر أو يساوي العدد  20"});
          }

      }else{
          $('#'+e.target.name).val('');
          setdata({...data,msg:"يجب أن يحتوي المعدل على أعداد فقط"});
      }



    }
    return(
        <div className="col-md-12">
            <label className="text-info">{props.name}</label>
            <input type="text" pattern="^\d*([\.,]\d{0,3})?$"  onChange={onChange} id={props.id} name={props.id} className="form-control" />
            <small className="text-danger">{data.msg}</small>
            <small className="text-danger" id={"msg_"+props.id}></small>
        </div>
    )
}
function Customdate2(props)  {
    let date=null;
    const years =[];
    for (let i = getYear(new Date())-46; i <= getYear(new Date()); i += 1) {
        years.push(i);
    }
    const months = [
        "جانفي",
        "فيفري",
        "مارس",
        "آفريل",
        "ماي",
        "جوان",
        "جويلة",
        "أوت",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر"
    ];
    if(localStorage.getItem(props.id)){
        date=new Date(localStorage.getItem(props.id));
        $('#'+props.id).val(date);
    }

    const [startDate, setStartDate] = useState(date);
    return (
        <DatePicker  locale={ar} id={props.id} dateFormat="yyyy-MM-dd"  className="form-control"
                    renderCustomHeader={({
                                             date,
                                             changeYear,
                                             changeMonth,
                                             decreaseMonth,
                                             increaseMonth,
                                             prevMonthButtonDisabled,
                                             nextMonthButtonDisabled
                                         }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >

                            <select  className="form-control"
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select   className="form-control"
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                            >
                                {months.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>


                        </div>
                    )}

                    selected={startDate}
                    onChange={date=>setStartDate(date)}
        />
    );
};
function Customdate(props)  {
    let minDate=new Date(getYear(new Date())-60, 1, 1, 0, 0, 0, 0);
    let maxDate=new Date(getYear(new Date())-17, 1, 1, 0, 0, 0, 0);
    let date=null;
    if(props.user.datebirth){
        if(props.user.datebirth){
            // let datebirth=props.user.datebirth.split('-');
            date=new Date(props.user.datebirth);
           // console.log(date);
        }
    }
    const [startDate, setStartDate] = useState(date);
    const years =[];
    for (let i = getYear(new Date())-60; i <= getYear(new Date())-17; i += 1) {
        years.push(i);
    }
    // $('.react-datepicker-wrapper input').attr('id',props.id);
    $('.react-datepicker-wrapper input').attr('readonly','readonly');
    const months = [
        "جانفي",
        "فيفري",
        "مارس",
        "آفريل",
        "ماي",
        "جوان",
        "جويلة",
        "أوت",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر"
    ];
    return (
        <DatePicker locale={ar} id={props.id} dateFormat="yyyy-MM-dd"  minDate={minDate} maxDate={maxDate}  className="form-control"
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled
                                 }) => (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center"
                    }}
                >

                    <select className="form-control"
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select className="form-control"
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>


                </div>
            )}
            selected={startDate}
            onChange={date => setStartDate(date)}
        />
    );
};
function Defaultformulaire(props) {
    let history=useHistory();
    const [data, setdata] = useState({user: JSON.parse(localStorage.getItem('user')),
    gendre:['أعزب' ,'متزوج'],diplomes:props.diplomes,checked_gendre:0,
        villes:props.villes,deligations:props.deligations,deligation:null,id_deligation:null,
        dateofbirth:null,cituation:'',ville:null, adr:'',codepostal:'',codespostals:null,
        score:null,msg:'',slug:localStorage.getItem('slug')});
    const Changegendre= (e)=>{
        e.preventDefault();
        setdata({...data,gendre:null,checked_gendre:0});
        if(e.target.value==='Male'){
            setdata({...data,gendre:['أعزب' ,'متزوج'],checked_gendre:e.target.value});
        }else
        {
            setdata({...data,gendre:['عزباء' ,'متزوجة'],checked_gendre:e.target.value});
        }

    }
    const Deligation=(id_ville)=> {
        if(props.villes && id_ville){
            setdata({...data, deligations: null });
            try {
                axios.get(API_BASE_URL+ '/deligation/'+id_ville)
                    .then((result) => {
                        // console.log(result.data.data);
                        setdata({...data, deligations: result.data.data});

                        if(data.user.id_deligation){
                            $('#deligation option:selected').val(data.user.id_deligation);
                        }
                    });

            } catch (err) {

                console.error(err);
            }
        }
    }
    const CodePostal=(id_deligation)=> {
        if(id_deligation){

            try {
                axios.get(API_BASE_URL+ '/codepostal/'+id_deligation)
                    .then((result) => {
                        // console.log(result.data.data);
                        setdata({...data, codespostals: result.data.data});

                        if(data.user.id_code){
                            $('#codepostal option:selected').val(data.user.id_code);
                        }
                    });

            } catch (err) {

                console.error(err);
            }
        }
    }
    const getDiplome = ()=> {

            let diplomes= props.diplomes;
            // let html='<option></option>';
            // for(var k=0;k<diplomes.length;k++)
            //     html+='<option>'+diplomes[k]+'</option>';
            setdata({...data,diplomes: diplomes });




    }
    const CheckValue=(e)=>{
        e.preventDefault();
        let valide=true;
        $('#msggendre').html('');
        $('#msg_dateBirth').html("");
        $('#msg_cituation').html("");
        $('#msg_adr').html("");
        $('#msg_city').html("");
        $('#msg_deligation').html("");
        $('#msg_codepostal').html("");
        if(!$('#male').is(':checked') && !$('#female').is(':checked')){
            $('#male').attr('class','border-danger');
            $('#female').attr('class','border-danger');
            $('#msggendre').html('<small class="text-danger">يجب إختيار الجنس</small>');
            valide=false;
        }else{
            $('#msggendre').html('');
        }
        if($('#dateBirth').val()==""){
            $('#msg_dateBirth').html("يجب إدخال سنة الولادة");
            valide=false;
        }else{
            $('#msg_dateBirth').html("");
        }
        if($('#cituation option:selected').val()==""){
            $('#msg_cituation').html("يجب إختيار الحالة المدنية");
            valide=false;
        }else{
            $('#msg_cituation').html("");
        }

        if(  $('#adr').val()===''){
            valide=false;
            $('#msg_adr').html("يجب إدخال عنوان السكن");
        }else{
            $('#msg_adr').html("");
        }
        if($('#city option:selected').val()==""){
            $('#msg_city').html("يجب إختيار الولاية");
            valide=false;
        }else{
            $('#msg_city').html("");
        }
        if($('#deligation option:selected').val()==""){
            $('#msg_deligation').html("يجب إختيار المعتمدية");
            valide=false;
        }else{
            $('#msg_deligation').html("");
        }
        if($('#codepostal').val()==""){
            $('#msg_codepostal').html("يجب إدخال الترقيم البريدي");
            valide=false;
        }else{
            $('#msg_codepostal').html("");
        }
        props.champs.forEach(champ =>{
            $('#msg_'+champ.idname).html('<small class="text-danger"></small>');
           if(champ.type==="Plusieurs valeurs"){
               $('#msg_'+champ.idname).after('<small class="text-danger"></small>');
               if($('#'+champ.idname+' option:selected').val()===''){
                   $('#msg_'+champ.idname).html('يجب إختيار '+champ.name);
                   valide=false;
               }else{
                   $('#msg_'+champ.idname).html('');
               }
           }else{
               if($('#'+champ.idname).val()===''){
                   $('#msg_'+champ.idname).html('يجب إدخال  '+champ.name);
                   valide=false;
               }else{
                   $('#msg_'+champ.idname).html('<small class="text-danger"></small>');
               }
           }

        });
        if(valide==true)
            SaveCondidature(e);
        else{

        }

    }
    const SaveCondidature=(e) =>{
        localStorage.removeItem('user');

        e.preventDefault();
            var bodyFormData = new FormData();
            bodyFormData.append('access_token', sessionStorage.getItem('access_token'));
            bodyFormData.append('gendre', data.checked_gendre);
            bodyFormData.append('dateBirth', $('#dateBirth').val());
            bodyFormData.append('cituation', $('#cituation option:selected').val());
            bodyFormData.append('adr', $('#adr').val());
            bodyFormData.append('ville', $('#city option:selected').val());
            bodyFormData.append('deligation', $('#deligation option:selected').val());
            bodyFormData.append('codepostal', $('#codepostal option:selected').val());
            bodyFormData.append('slug', props.slug);
            props.champs.forEach(champ =>{
                bodyFormData.append(champ.idname, $('#'+champ.idname).val());
                if(champ.type==='Date'){
                    localStorage.removeItem(champ.idname);
                    localStorage.setItem(champ.idname,$('#'+champ.idname).val());

                }

               // console.log($('#'+champ.idname+" option:selected").val());
            });
            let axiosConfig = {
                headers: { 'Content-Type': 'multipart/form-data' }
            };
            axios.post(API_BASE_URL+'/savecondidaure', bodyFormData,axiosConfig)
                .then((result) => {
                    if(result.data.valide){
                        localStorage.setItem('user',JSON.stringify(result.data.user));
                        localStorage.setItem('city',result.data.city);
                        localStorage.setItem('code',result.data.code);
                        localStorage.setItem('speciality',result.data.speciality);
                        localStorage.setItem('ville',result.data.ville);
                        localStorage.setItem('deligation',result.data.deligation);
                        localStorage.setItem('slug',result.data.slug);
                        localStorage.setItem('validate_date',result.data.validate_date);
                        setdata({...data,slug:result.data.slug,msg: result.data.msg,score:result.data.score});


                    }else{
                        localStorage.setItem('user',JSON.stringify(result.data.user));
                        localStorage.setItem('msgInscrit',result.data.msg);
                        localStorage.setItem('resultInscrit',false);
                        localStorage.removeItem('slug',null);
                        localStorage.removeItem('code',null);
                        setdata({...data,msg: result.data.msg,slug:null,score:null});
                    }
                    const timeId = setTimeout(() => {
                        history.push("/dashboard");
                    }, 2000);

                });



    }

    const onChange = (e) => {
        e.preventDefault();

        if(e.target.type && (e.target.type==='text' || e.target.type==='date' || e.target.type==='select-one' )){
            setdata({ ...data, [e.target.name]: e.target.value });
            if(e.target.name.toString()==='ville'){
                Deligation(e.target.value);
            }
            if(e.target.name.toString()==='id_deligation'){

                CodePostal(e.target.value);
            }
        }

    }
    return(
        <div className=" border-danger">
                    <form className="d-flex flex-column text-justify bg-light" >
                    <div className="col-lg-12">
                        <label className="text-info">رقم ب ت </label>
                        <input readOnly value={data.user.cin} type="text" name="Cin" className="form-control" />
                    </div>
                    <div className="row col-lg-12">
                        <div className="col-md-5">
                            <label className="text-info">	الإسم</label>
                            <input readOnly value={data.user.firstname} type="text" name="firstname" className="form-control" />
                        </div>
                        <div className="col-md-5">
                            <label className="text-info">		اللقب</label>
                            <input readOnly value={data.user.lastname} type="text" name="lastname" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-12">
                       <div className="row col-lg-12 " >
                           <div className="col-md-12 ">
                               <label className="text-info">		الجنس</label>
                           </div>

                           <br />
                           <div className="col-md-6 ">
                               {data.user && !data.user.gendre &&
                               <div> <input type="radio" id="male" checked={data.checked_gendre=='Male'} onChange={Changegendre} className="margin " value="Male"  name="Male" />      ذكر</div>

                               }
                               {data.user && data.user.gendre &&
                               <div>  <input type="radio" id="male"  onChange={Changegendre} className="margin " value="Male"  name="Male" />      ذكر</div>

                               }
                           </div>
                           <div className="col-md-6 ">
                               {data.user && !data.user.gendre &&
                               <div>
                                   <input type="radio" id="female" checked={data.checked_gendre == 'Female'}
                                          onChange={Changegendre} className="margin  " value="Female" name="Female"/>   أنثى
                               </div>

                               }
                               {data.user && data.user.gendre &&
                               <div> <input type="radio" id="female"
                                            onChange={Changegendre} className="margin  " value="Female" name="Female"/>   أنثى</div>

                               }
                           </div>
                           <div className="col-md-12" id="msggendre">
                           </div>
                       </div>
                   </div>
                    <div className="row col-lg-12">
                        <div className="col-md-6">
                            <label className="text-info">			تاريخ الولادة</label>
                            {/*<input dir="rtl"  onChange={onChange} value="yyyy/mm/dd" type="date" name="dateBirth" className="form-control" />*/}
                            <Customdate user={data.user} name={data.dateofbirth} id="dateBirth"/>
                            <small id="msg_dateBirth" className="text-danger"></small>
                        </div>
                        <div className="col-md-6">
                            <label className="text-info">	الحالة المدنية</label>
                           <select className="form-control"
                                   name="cituation" id="cituation" onChange={onChange}>
                               <option></option>
                               {data.gendre && data.gendre.map((gender, i) =>
                                   <option key={i} value={gender} >
                                       {gender}
                                   </option>
                               )}
                               <option value="other">أخرى</option>
                           </select>
                            <small className="text-danger" id="msg_cituation"></small>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label className="text-info">			 العنوان</label>
                        <textarea  id="adr"  name="adr" className="form-control" onChange={onChange}  />
                        <small className="text-danger" id="msg_adr"></small>
                    </div>
                    <div className="row col-md-12">
                    <div className="col-md-6">
                        <label className="text-info">	الولاية</label>
                        <select name="ville" id="city" className="form-control custom-select-lg" onChange={onChange} >
                            <option></option>
                            { props.villes && props.villes.map((ville, i) =>
                                <option key={i} value={ville.id}  selected={data.user.id_ville===ville.id} onClick={() => {Deligation(ville.id)}}>
                                    {ville.name}
                                </option>
                            )}
                        </select>
                        <small className="text-danger" id="msg_city"></small>
                    </div>
                    <div className="col-md-6" name="deligation">
                        <label className="text-info"> المعتمدية</label>
                        {data.deligations &&
                        <select name="id_deligation" id="deligation" className="form-control custom-select-lg" onChange={onChange} >
                            <option></option>
                            { data.deligations && data.deligations.map((deligation, i) =>
                                <option key={i} selected={data.id_deligation===deligation.id}  value={deligation.id} >
                                    {deligation.name}
                                </option>
                            )}
                        </select>}
                        {!data.deligations &&
                        <select name="id_deligation" id="deligation" className="form-control custom-select-lg" onChange={onChange} >
                            <option></option>
                            { props.deligations && props.deligations.map((deligation, i) =>
                                <option key={i} selected={data.user.id_deligation===deligation.id} value={deligation.id} >
                                    {deligation.name}
                                </option>
                            )}
                        </select>}
                        <small className="text-danger" id="msg_deligation"></small>

                    </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row col-md-12" name="codepostal">
                            <label className="text-info">الترقيم البريدي</label>
                            {data.codespostals &&
                            <select name="codepostal" id="codepostal" className="form-control custom-select-lg" onChange={onChange} >
                                <option></option>
                                { data.codespostals && data.codespostals.map((codepostal, i) =>
                                    <option key={i}   value={codepostal.id}>
                                        {codepostal.name}
                                    </option>
                                )}
                            </select>}
                            {!data.codespostals &&
                            <select name="codepostal" id="codepostal" className="form-control custom-select-lg" onChange={onChange} >
                                <option></option>
                                { props.codespostals && props.codespostals.map((code, i) =>
                                    <option key={i} selected={data.user.id_code===code.id} value={code.id}>
                                        {code.name}
                                    </option>
                                )}
                            </select>}
                            <small className="text-danger" id="msg_deligation"></small>

                        </div>


                        <small className="text-danger" id="msg_codepostal"></small>
                    </div>

                    <div className="row col-md-12" id="listinput">

                        {props.champs && props.champs.map((champ, i) =>
                            <Forminput key={i} componentData={champ}  />
                        )}

                    </div>
                        {!data.msg && !data.slug &&
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <hr/>
                                    <input type="submit" value="تسجيل" onClick={CheckValue}
                                           className="btn btn-success"/>
                                </div>
                            </div>


                        </div>
                        }
                        {!data.msg && data.slug &&
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                    <input type="submit" value="تغير" onClick={CheckValue}
                                           className="btn btn-success"/>
                                </div>
                            </div>


                        </div>
                        }
                        {data.msg &&
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">

                                    <hr />
                                    <input type="submit" value="تغير" onClick={CheckValue}
                                           className="btn btn-success"/>
                                </div>
                            </div>
                            <hr />
                            <Alert
                                variant="success" onClose={() => data.msg} >
                                <Alert.Heading>نتيجة التسجيل</Alert.Heading>

                                <p className="font-italic font-weight-bold">
                                    {data.msg}
                                </p>
                                <p className="font-italic font-weight-bold">
                                    {data.score && data.score>0 &&
                                     'النتيجة المتحصل عليها : '+ data.score.toFixed(2)
                                    }
                                </p>
                            </Alert>

                        </div>

                        }

            </form>
            <hr />



        </div>
    );
}

export  default Formulairecondidature;
