import React from 'react';
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import {API_BASE_URL} from "../config";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Alert from "react-bootstrap/Alert";
import { Redirect } from 'react-router-dom';
class Topdashboard extends React.Component
{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user: null,
            slug:null,
            remove:null,
            msg: null,
            is_closed:props.is_closed
        }

    }

    RemoveFolder =(e)=> {
        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1> عملية فسخ الملف ؟ </h1>
                        <p>هل أنت متاكد من فسخ الملف ؟</p>
                        <button className="btn btn-warning" onClick={onClose}>إلغاء العملية</button>
                        <button className="btn btn-danger"
                                onClick={() => {
                                    var bodyFormData = new FormData();
                                    bodyFormData.append('access_token', sessionStorage.getItem('access_token'));
                                    let axiosConfig = {
                                        headers: { 'Content-Type': 'multipart/form-data' }
                                    };
                                    axios.post(API_BASE_URL+'/RemoveDossier', bodyFormData,axiosConfig)
                                        .then((result) => {
                                            if(result.data ) {

                                                this.setState({msg: result.data.msg});
                                                this.setState({user:JSON.stringify(result.data.user)});
                                                localStorage.removeItem('user');
                                                localStorage.removeItem('slug');
                                                localStorage.setItem('user',JSON.stringify(result.data.user));
                                                localStorage.setItem('token', result.data.user.token);
                                                localStorage.setItem('is_connected','true');
                                                this.setState({is_connected:sessionStorage.getItem('is_connected')});
                                                this.setState({remove:1});
                                                const timeId = setTimeout(() => {
                                                    window.location.replace("/dashboard");

                                                }, 1000);


                                            }
                                        });
                                    onClose();
                                }}
                        >
                            نعم تفعيل عملية الفسخ
                        </button>
                    </div>
                );
            }
        });

    }
    componentDidMount() {

            this.setState({is_connected:sessionStorage.getItem('is_connected')});
            if(localStorage.getItem('slug'))
             this.setState({slug:localStorage.getItem('slug')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});


    }
    render() {
        if(!this.state.remove)
        return (
            <div>
                <Alert
                    variant="warning" onClose={() => localStorage.getItem('is_connected')} >
                    <Alert.Heading></Alert.Heading>

                    <p className="font-italic">
                        بعد الإطلاع على بلاغ المناظرة يجب على كل مشترك إختيار خطة واحدة وفي صورة تغير الخطة يجب عليه فسخ الملف وإعادة التسجيل في الخطة المرغوب فيها
                    </p>
                    { this.state.slug && !this.state.msg && !this.props.is_closed &&
                    <button className="btn btn-danger" onClick={this.RemoveFolder}>فسخ الملف</button>
                    }
                </Alert>

            </div>


        )
      else
            return (
                <div>
                    <Alert
                        variant="warning" onClose={() => localStorage.getItem('is_connected')} >
                        <Alert.Heading></Alert.Heading>

                        <p className="font-italic">
                            بعد الإطلاع على بلاغ المناظرة يجب على كل مشترك إختيار خطة واحدة وفي صورة تغير الخطة يجب عليه فسخ الملف وإعادة التسجيل في الخطة المرغوب فيها
                        </p>


                    </Alert>

                    {this.state.msg &&
                    <Alert
                        variant="danger" onClose={() => localStorage.getItem('is_connected')}>
                        <Alert.Heading></Alert.Heading>
                        {this.state.msg}
                    </Alert>
                    }
                </div>

            )
    }

}



export  default Topdashboard;
