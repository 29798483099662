import React from "react";
import axios from "axios";
import {API_BASE_URL, URL_DASHBOARD} from "../config";
import Pdfview from "./Pdfview";
import {Link} from "react-router-dom";
class Printdocument extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            pdflink:null,
            slug: localStorage.getItem('slug')
        }

    }
     getFormulaire (){
        let cin =JSON.parse(localStorage.getItem('user')).cin;
        if(cin){
            var bodyFormData = new FormData();
            bodyFormData.append('user_cin', cin);

            let axiosConfig = {
                headers: { 'Content-Type': 'multipart/form-data' }
            };
            axios.post(API_BASE_URL+'/getformulaire', bodyFormData,axiosConfig)
                .then((result) => {
                    if(result.data ) {
                        if(result.data.result && result.data.result!='null'){
                            this.setState({resultform:JSON.parse(JSON.stringify(result.data.result))});
                            this.setState({pdflink:result.data.pdflink});
                        }


                    }


                });
        }

    }
    componentDidMount() {

        if(sessionStorage.getItem('is_connected')){
            this.setState({is_connected:localStorage.getItem('is_connected')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});
            if(this.state.slug)
                this.getFormulaire();
        }


    }
    render() {

            return (



                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                {this.state.slug &&
                                                <div className="form-group col-md-6">
                                                    <Link to={"/pdf/"+this.state.pdflink} target="_blank" className="btn btn-success btn-group-lg">
                                                        <i className="fa fa-save"></i>&ensp;&ensp;&ensp;
                                                        طباعة && تحميل إستمارة الترشح
                                                    </Link>

                                                </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                )
        }



}
export default Printdocument;
