import React, {Component, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha/lib/esm/recaptcha-wrapper";
import {getSetting} from "../sotre/actions/settingAction";
import {connect} from 'react-redux'

class Auth extends  Component{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            Check:null,
            msg_concours:null,
            is_closed:false,
            is_blocked:false,
            setting:null,
        }

    }
    useEffect =(setting)=>{
        const interval = setInterval(() => {
            this.getDateDiff(setting);
        }, 1000);
        return () => clearInterval(interval);
    }
    getDateDiff(setting) {
        var table=setting.concours.dated.split(' ');
        var datetime=table[0].split('-');
        var time=table[1].split(':')
        // console.log(datetime[0]+','+datetime[1]+','+datetime[2]+','+time[0]+','+time[1]+','+time[2]);
        var date_debut=new  Date(datetime[0],datetime[1]-1,datetime[2],time[0],time[1],time[2]);

        var date1=new Date();
        //console.log(date_debut+'***'+date1);
        if(date1<=date_debut){
            this.setState({is_blocked: true});
            var sec = (date_debut - date1) / 1000;
            // console.log('Sec:='+sec);
            var n = 24 * 3600;
            if (sec >=1) {
                var j = Math.floor(sec / n);
                var h = Math.floor((sec - (j * n)) / 3600);
                var mn = Math.floor((sec - ((j * n + h * 3600))) / 60);
                var sec2 = Math.floor(sec - ((j * n + h * 3600 + mn * 60)));
                var text = '';
                if (j > 1)
                    text = j + '(أيام) ';
                if (j == 0)
                    text = '';
                if (j == 1)
                    text = 'يوم ';
                if (h > 0)
                    text += ' ' + h + ' (ساعة) ';
                if (mn > 0)
                    text += ' ' + mn + ' (دقيقة)';
                text += ' ' + sec2 + '( ثانية ) ';

                this.setState({timestamp: text});
            }
        }
        else{
            this.setState({is_blocked: false});
            var table=setting.concours_inscrit.date_fininscription.split(' ');
            var datetime=table[0].split('-');
            var time=table[1].split(':')
            // console.log(datetime[0]+','+datetime[1]+','+datetime[2]+','+time[0]+','+time[1]+','+time[2]);
            var date2=new  Date(datetime[0],datetime[1]-1,datetime[2],time[0],time[1],time[2]);

            var sec = (date2 - date1) / 1000;
            // console.log('Sec:='+sec);
            var n = 24 * 3600;
            if (sec >=1) {
                var j = Math.floor(sec / n);
                var  h = Math.floor((sec - (j * n)) / 3600);
                var   mn = Math.floor((sec - ((j * n + h * 3600))) / 60);
                var   sec2 = Math.floor(sec - ((j * n + h * 3600 + mn * 60)));
                var text='';
                if(j>1)
                    text=j+'(أيام) ';
                if(j==0)
                    text='';
                if(j==1)
                    text= 'يوم ';
                if(h>0)
                    text+=' '+h+' (ساعة) ';
                if(mn>0)
                    text+=' '+mn+' (دقيقة)';
                text+=' '+sec2+'( ثانية ) ';

                this.setState({timestamp: text});

            }else{
                this.props.setting.is_closed=true;
                this.setState({is_closed:true})

            }
        }


    }
    getLastConcours=() =>{
        try {
            const {setting} = this.props.setting;
            this.setState({setting:setting});
            if (setting.concours_inscrit){
                this.getDateDiff(setting);
                this.useEffect(setting);
            }
        } catch (err) {

            console.error(err);
        }
    }

    componentDidMount() {
        sessionStorage.clear();
        localStorage.clear();
        if(sessionStorage.getItem('is_connected')){
            this.setState({is_connected:sessionStorage.getItem('is_connected')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});
        }
        this.props.getSetting();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.setting){
            this.getLastConcours();
        }
    }



    render() {
        if(!this.state.is_blocked) {
            if (!this.state.is_connected)
                return (
                    <section className="  text-center container">
                        {!this.state.msg_concours &&
                        <div className="col-lg-6 mx-auto">

                            <h4 className="font-weight-light">التعريف بالمستعمل</h4>
                            <br/>
                            <Authentification closed={this.state.is_closed} blocked={this.state.is_blocked}
                                              msg={this.state.msg_concours}/>


                        </div>
                        }
                        {this.state.msg_concours &&
                        <div className="col-lg-6 mx-auto">
                            <h4> {this.state.msg_concours} </h4>


                        </div>
                        }

                    </section>
                );
            else
                return (
                    <section className="  text-center container">

                        <div className="col-lg-6 mx-auto">


                            <div className="col-lg-6 mx-auto">
                                <h4 className="font-weight-light"> مرحبا {this.state.user.lastname} {this.state.user.firstname}</h4>
                                <br/>
                                <MenuLinkCondition to="/dashboard" label="لوحة القيادة"
                                                   classname="btn btn-success btn-group-lg"></MenuLinkCondition>
                            </div>


                        </div>

                    </section>
                );
        }else{
            return(
                <div></div>
            )
        }
    }
}

function Authentification(props) {
    let history = useHistory();
    const [data, setdata] = useState({Cin:'',Password:'',is_connected:null,user:{},Check:null,NotCheck:null,is_closed:props.closed});

   // console.log(props.closed+'***'+props.blocked);
    const apiUrl = API_BASE_URL+'/check-auth';
    const ConnectUser = (e)   =>{
        e.preventDefault();
        sessionStorage.clear();
        localStorage.clear();
        setdata({...data,is_connected:null});
        var bodyFormData = new FormData();
        bodyFormData.append('Cin', data.Cin);
        bodyFormData.append('Password', data.Password);
        bodyFormData.append('Check', data.Check);
        let axiosConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        axios.post(apiUrl, bodyFormData,axiosConfig)
            .then((result) => {

                if(result.data && result.data.user) {
                    setdata({...data,is_connected:'false'});
                    setdata({...data,NotCheck:true});
                    if(parseInt(result.data.check)===1){
                        if(result.data.slug)
                            localStorage.setItem('slug',result.data.slug);
                        setdata({...data,user:result.data.user});

                        if(!result.data.access_token){
                            history.push('/check-register/'+data.Cin);
                        }else {
                            sessionStorage.setItem('is_connected', true);

                            sessionStorage.setItem('access_token', result.data.access_token);
                            localStorage.setItem('user', JSON.stringify(result.data.user));
                            localStorage.setItem('city', result.data.city);
                            localStorage.setItem('code', result.data.code);
                            localStorage.setItem('ville', result.data.ville);
                            localStorage.setItem('deligation', result.data.deligation);
                            localStorage.setItem('validate_date', result.data.validate_date);
                            localStorage.setItem('created_at', result.data.created_date);
                            localStorage.setItem('speciality', result.data.speciality);
                            setdata({...data, is_connected: sessionStorage.getItem('is_connected')});
                            history.push('/dashboard');
                        }

                    }else{

                        setdata({...data,NotCheck:true});


                    }

                }
                else{

                    setdata({...data,is_connected:'false'});


                }
            }).catch(error => {

        });
    }
    const onChange = (e) => {
        e.persist();
        if(e.target.name==='Check'){
            setdata({ ...data, [e.target.name]: !data.Check });
        }
        else
            setdata({ ...data, [e.target.name]: e.target.value });

    }

    return (

        <form className="needs-validation" dir="rtl" onSubmit={ConnectUser} >
            {data.NotCheck &&
            <Alert
                variant="warning" onClose={() => sessionStorage.getItem('is_connected')} >
                <Alert.Heading>تحذير!</Alert.Heading>

                <p>
                    لا يمكنك إكمال الملف قبل أن توافق على شروط المشاركة في المناظرة
                </p>

            </Alert>}
            {data.is_connected ==='false' &&
            <Alert
                variant="danger" onClose={() => localStorage.getItem('is_connected')} >
                <Alert.Heading>تحذير!</Alert.Heading>

                <p>
                    الرجاء التثبت في رقم بطاقة التعريف أو الرقم السري
                </p>

            </Alert>
            }
            <div className="col-md-12">
                <label htmlFor="cin" className="form-label text-justify ">رقم بطاقة التعريف الوطنية <span
                    className="spanDanger">*</span> </label>
                <input type="text" className="form-control" id="Cin" pattern="[0-9]*" name="Cin" value={data.Cin} onChange={onChange} required/>

            </div>
            <div className="col-md-12">
                <label htmlFor="password" className="form-label text-justify ">كلمت العبور <span
                    className="spanDanger">*</span></label>
                <input type="password" className="form-control"  value={data.Password} id="Password" name="Password" onChange={onChange} required/>

            </div>
            {data.NotCheck &&
            <div className="col-md-12">
                <hr />
                <Conditionconcours />

                <div className="row">
                    <div className="col-md-2">
                        <input type="checkbox"  value={data.Check} id="Check" name="Check" onChange={onChange} />

                    </div>
                    <div className="col-md-10">
                        اطلعت على شروط المشاركة في المناظرة وأوافق عليها
                    </div>
                </div>
            </div>
            }

            <br/>

            <div className="row">

                {!props.closed && !props.blocked && !data.NotCheck &&
                <div className="left margin">
                    <MenuLinkCondition to="/register" classname="btn btn-info" label="  1- إنشاء حساب شخصي"/>
                </div>
                }
                {data.NotCheck &&
                <div className="left margin">
                    <button className="btn btn-success" required>تسجيل الدخول</button>
                </div>
                }
                {!data.NotCheck &&
                <div>
                    <div className="left margin">
                        <MenuLinkCondition to="/changepassword" classname="btn btn-danger" label="نسيت كلمة العبور"/>
                    </div>
                    <div className="left margin">
                        <button className="btn btn-success" required> 2- تسجيل الدخول </button>
                    </div>

                </div>

                }






            </div>
            <div className="col-md-12">
                <ReCAPTCHAForm />
            </div>


        </form>
    );

}
class  Conditionconcours extends Component{
    constructor(props) {
        super(props);
        this.state={
            condition: ''
        }

    }

    componentDidMount() {
        this.getCondition();
    }


    getCondition = () => {

        const apiUrl = API_BASE_URL+'/get-condition';
        axios.get(apiUrl)
            .then((result) => {
                this.setState({condition: result.data.condition})
            }).catch(error => {
            console.log(error)
        });
    }
    render() {
        return(
            <div className="text-center condition" dangerouslySetInnerHTML={{ __html: this.state.condition.description }}>

            </div>
        );
    }


}
const ReCAPTCHAForm = (props) => {
    const recaptchaRef = React.useRef();

    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        // apply to form data
    }

    return (
        <div  onLoad={onSubmitWithReCAPTCHA}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdoEFAaAAAAADuQUL7Yz_JVTVJ4T5AraCBkLhzl"
            />
        </div>


    )

}
function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
const mapStateToProps  = (state) => ({setting:state.setting})

export default connect(mapStateToProps, {getSetting})(Auth)
