import React, { useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha/lib/esm/recaptcha-wrapper";
class Changepassword extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},

        }

    }
    componentDidMount() {
    }
    render() {
        return(
            <section className="  text-center container">

                <div className="col-lg-6 mx-auto">

                    <h4 className="font-weight-light">تغير كلمت العبور </h4>
                    <br />
                    <Formpassword/>


                </div>

            </section>
        )
    }
}
function Formpassword(props) {
    let history = useHistory();
    const [data, setdata] = useState({Etape_valide:1,Cin:'',Code:null,etape:1,Password: null,Msg:null,error:null,readonly:null,Msg_password:null});

    const apiUrl = API_BASE_URL+'/sendchangepwd';
    const CheckCin = (e) => {
        e.preventDefault();

        setdata({...data,Msg:null});
        setdata({...data,error: null});
        var bodyFormData = new FormData();
        bodyFormData.append('cin', data.Cin);
        bodyFormData.append('etape', data.etape);
        if(data.etape===2)
            bodyFormData.append('code', data.Code);
        if(data.etape===3){
            bodyFormData.append('password', $('#Password').val());
            console.log($('#Password').val());
        }


        let axiosConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        axios.post(apiUrl, bodyFormData,axiosConfig)
            .then((result) => {

                if(result.data && result.data.msg!=null){
                    if(data.etape===1){
                       setdata({...data,Etape_valide:0,etape:2,Msg:'لقد تم إرسال الرمز عن طريق إرسالية قصيرة، الرجاء إدخل الرمز',error: null,readonly: true});

                    }
                    if(data.etape===2){

                        setdata({...data,etape:3,Msg:'لقد تم تفعيل الرمز',error: null,Etape_valide:0});
                    }

                    if(data.etape===3)
                    {
                        setdata({...data,Msg:'لقد تم التغير بنجاح',error: null,Etape_valide:0});
                        setTimeout(() => {
                            history.push('/');
                        }, 3000)

                    }


                }else{
                   if(data.etape===1)
                     setdata({...data,Msg:'رقم بطاقة التعريف الوطنية غير مسجل، الرجاء التثبت',error: true});
                    if(data.etape===2)
                        setdata({...data,Msg:'الرمز غير مطابق',error: true});
                }



            }).catch(error => {
            console.log(error)
        });
    }
    const onChange = (e) => {
        e.persist();
        setdata({...data,Msg:null});
        setdata({...data,error: null});
        if(e.target.name.toString()==='Cin'){
            if(e.target.validity.valid)
                setdata({ ...data, [e.target.name]: e.target.value });
            else
                setdata({ ...data, [e.target.name]:'' });
        }
        if(e.target.name.toString()==='Code'){

            setdata({...data,error:null});
            setdata({ ...data, [e.target.name]:e.target.value });
            if(e.target.value.length===4)
                setdata({...data,[e.target.name]:e.target.value,Etape_valide: 2});
        }
        if(e.target.name.toString()==='Password'){

            console.log('Password'+e.target.value);
            setdata({ ...data, [e.target.name]: e.target.value });
            if(e.target.value.length<8){
                setdata({...data,Msg_password: "على الأقل ثمانية حروف مسترسلة" ,Etape_valide:0});
            }
            else{
                setdata({ ...data, Etape_valide: 3,Msg_password: "" });
            }
        }



    }


    return (

        <form className="needs-validation" dir="rtl" onSubmit={CheckCin}>
            {data.error && data.Msg &&
            <div className="bg-danger">
                <h5 className="text-center text-white">{data.Msg}</h5>
            </div>
            }
            {!data.error && data.Msg &&
            <div className="bg-info">
                <h5 className="text-center text-white">{data.Msg}</h5>
            </div>
            }
            <div className="col-md-12">
                <label htmlFor="cin" className="form-label text-justify ">رقم بطاقة التعريف الوطنية <span
                    className="spanDanger">*</span> </label>
                <input type="text" className="form-control" readOnly={data.readonly} id="Cin" pattern="[0-9]*" name="Cin" value={data.Cin} onChange={onChange} required/>

            </div>
            {data.etape===2 &&
            <div className="col-md-12">
                <label htmlFor="password" className="form-label text-justify "> الرمز <span
                    className="spanDanger">*</span></label>
                <input type="text" className="form-control"  value={data.Code} id="Code" name="Code" onChange={onChange} required/>

            </div>}

            {data.etape===3 &&
            <div className="col-md-12">
                <label htmlFor="password" className="form-label text-justify ">كلمة العبور<span
                    className="spanDanger">*</span></label>
                <input type="password" className="form-control"  placeholder=""   value={data.Password} id="Password" name="Password" onChange={onChange} required/>
                <span className="text-danger">{data.Msg_password}</span>
            </div>}
            <div className="col-md-12">
                <ReCAPTCHAForm />
            </div>
            <br/>

            <div className="row">

                <div className="left margin">
                    {data.Etape_valide===1 &&
                            <button  className="btn btn-success">
                                {data.etape===1 && "الخطوة الأولى: التثبت من رقم بطاقة التعريف"}
                            </button>
                    }
                    {data.Etape_valide===2 &&
                    <button  className="btn btn-success">
                        {data.etape===2 && "الخطوة الثانية: التثبت من الرقم السري"}
                    </button>
                    }
                    {data.Etape_valide===3 &&
                    <button  className="btn btn-success">
                        {data.etape===3 && "الخطوة الأخيرة : التفعيل"}
                    </button>
                    }



                </div>
                <div className="left margin">
                    <MenuLinkCondition to="/" classname="btn btn-warning" label="إلغاء التغير"/>
                </div>





            </div>


        </form>
    );

}
const ReCAPTCHAForm = (props) => {
    const recaptchaRef = React.useRef();

    const onSubmitWithReCAPTCHA = async () => {
         await recaptchaRef.current.executeAsync();

        // apply to form data
    }

    return (
        <div onLoad={onSubmitWithReCAPTCHA}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdoEFAaAAAAADuQUL7Yz_JVTVJ4T5AraCBkLhzl"
            />
        </div>
    )

}
function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
export default Changepassword;
