import React , { useState} from 'react';
import {Link,useHistory } from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import Auth from "./Auth";
class  Register extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            sujets:null,
            isLoading: null,
            token: null,
            etat:'',
            is_connected: null,
            user:{},
            is_closed:false
        }
    }
    componentDidMount() {
        this.getDateInscription();
        if(sessionStorage.getItem('is_connected')){
            this.setState({is_connected:sessionStorage.getItem('is_connected')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});

        }else{
            this.getToken();
        }
    }
    componentWillUnmount() {
    }

    getDateDiff=(date_fininscrit)=> {
        var date1=new Date();
        var date2=new  Date(date_fininscrit);
        var sec = (parseInt(date2 - date1) )/ 1000;

        if (sec >= 1) {

            this.setState({is_closed:false})


        }else{
            this.setState({is_closed:true})
        }

    }
    useEffect =(date_fininscrit)=>{
        const interval = setInterval(() => {
            this.getDateDiff(date_fininscrit);
        }, 10000);
        return () => clearInterval(interval);
    }
    getDateInscription=()=>{
        try{
            axios.get(API_BASE_URL+ '/getFinInscription')
                .then((result) => {

                    if(result.data.date){
                        this.getDateDiff(result.data.date);
                        this.useEffect(result.data.date);
                    }

                });

        }
        catch(e){

        }
    }
    async getToken(){
        if (! this.state.token) {
            try {

                const response = await fetch(API_BASE_URL + '/access', {});
                const token = await response.json();

                this.setState({ token: token.data, isLoading: false});
            } catch (err) {
                 console.error(err);
            }
        }
    }
    render() {
        if(!this.state.is_connected )
            if(!this.state.is_closed)
     return(
         <section className="  text-center container">

             <div className="col-lg-6 mx-auto">
                 <h4 className="font-weight-light">إنشاء حساب</h4>
                <RegisterUser/>
             </div>

         </section>




     );else{
                return (
                    <Auth />
                );
            }
        else{
            return (
                <section className="  text-center container">

                    <div className="col-lg-6 mx-auto">
                        <h4 className="font-weight-light"> مرحبا {this.state.user.lastname} {this.state.user.firstname}</h4>
                        <br />
                        <MenuLinkCondition to="/dashboard" label="لوحة القيادة" classname="btn btn-success btn-group-lg"></MenuLinkCondition>

                    </div>

                </section>
            );
        }
 }
}
function RegisterUser(props) {
    let history = useHistory();
    const [data, setdata] = useState({
        Email: '',Password: '',lastName: '',firstName: '', Cin:'',Gsm:'',
        Token: props.Token, CodeGsm:'' ,DPassword:'',Save:null,
        msg_firstname:'',msg_lastname:'',msg_cin:'',msg_gsm:'',msg_email:'',msg_pwd:'على الأقل ثمانية حروف مسترسلة',msg_dpwd:'مثل كلمة السر' ,
        etat_cin: false, etat_lastname: null, etat_firstname: null, etat_gsm: false,
        etat_pwd: false, etat_dpwd: false,etat_email: false });
    const apiUrl = API_BASE_URL+'/Register';


    const Registration = (e) => {
        e.preventDefault();
        Clearmsg(e);
        if(data.Password===data.DPassword){

            var bodyFormData = new FormData();
            bodyFormData.append('Email', data.Email);
            bodyFormData.append('Password', data.Password);
            bodyFormData.append('lastName', data.lastName);
            bodyFormData.append('firstName', data.firstName);
            bodyFormData.append('Cin', data.Cin);
            bodyFormData.append('Gsm', data.Gsm);

            let axiosConfig = {
                headers: { 'Content-Type': 'multipart/form-data' }
            };
            axios.post(apiUrl, bodyFormData,axiosConfig)
                .then((result) => {
                    data.etat=result.data.etat;
                    setdata({...data,Save:true});
                    setTimeout(() => {
                        history.push('/check-register/'+data.Cin);
                    }, 2000)

                });
        }

    }
    const Clearmsg = (e)=>{
        e.preventDefault();
        // e.target.setCustomValidity('الرجاء إدخال المعطيات');
      //   setdata({ ...data, msg_firstname: 'الرجاء إدخال المعطيات' });


    }
    const onChange = (e) => {
        e.preventDefault();
        setdata({ ...data, msg_firstname: '' });
        if(e.target.type==='text' || e.target.type==='email' || e.target.type==='password'){
            if(e.target.name!=='Email' && e.target.name!=='Password' && e.target.name!=='DPassword'){

                    setdata({ ...data, [e.target.name]: e.target.value, msg_firstname: '' });

            }else{

                    setdata({ ...data, [e.target.name]: e.target.value });


            }
        }



    }
    const CheckCin = (e) => {
        e.preventDefault();

            const data1 = {  Cin:data.Cin };
            if(data1.Cin.length>0 && data1.Cin.length==8) {
                let axiosConfig = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };
                var bodyFormData = new FormData();
                bodyFormData.append('cin', data1.Cin);
                axios.post(API_BASE_URL + "/check-cin", bodyFormData, axiosConfig)
                    .then((result) => {
                        let check = result.data.check;

                        if (check) {

                            setdata({...data,Cin: '', msg_cin: 'هذا الرقم موجود  الرجاء تغير رقم بطاقة التعريف',etat_cin:false});

                        } else {
                            setdata({...data,Cin:e.target.value,msg_cin: '',etat_cin: true});
                        }

                    });
            }else{
                setdata({...data,Cin: '', msg_cin: 'يجب أن يكون رقم  بطاقة التعريف متكون من ثمانية أعداد',etat_cin:false});
            }


    }
    const CheckPhone = (e) => {
         e.preventDefault();

            const data1 = {  phone: data.Gsm };
            if(data1.phone && data1.phone.length>0 && data1.phone.length==8){
                let axiosConfig = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                var bodyFormData = new FormData();
                bodyFormData.append('phone', data1.phone);
                axios.post(API_BASE_URL+"/check-phone", bodyFormData,axiosConfig)
                    .then((result) => {
                        let check=result.data.check;

                        if(check){

                            setdata({...data,Gsm:'',msg_gsm:'هذا الرقم موجود  الرجاء تغير ',etat_gsm:false});

                        }else{
                            setdata({...data,Gsm:e.target.value,msg_gsm:'',etat_gsm: true});
                        }

                    });
            }
            if(data1.phone &&  data1.phone.length<8 || data1.phone.length>8){
                setdata({...data,Gsm:'',msg_gsm:'يجب أن يكون رقم الهاتف متكون من ثمانية أعداد'});
            }



    }
    const CheckEmail = (e) => {
        e.preventDefault();
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(!pattern.test(e.target.value)){
            setdata({...data,Email:'','msg_email':'البريد الإلكتروني غير صحيح',etat_email: false});
        }else{

                setdata({...data,Email:e.target.value,'msg_email':'',etat_email: true});

        }
        if( pattern.test(data.email)){
            const data1 = {  email: data.Email };
            if(data1.email ){
                let axiosConfig = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                var bodyFormData = new FormData();
                bodyFormData.append('email', data1.email);
                axios.post(API_BASE_URL+"/check-email", bodyFormData,axiosConfig)
                    .then((result) => {
                        let check=result.data.check;

                        if(check){

                            setdata({...data,Email:'',msg_email:'البريد الإلكتروني موجود الرجاء تغير',etat_email: false});

                        }else{
                            setdata({...data,Email:e.target.value,msg_email:'',etat_email: true});
                        }

                    });
            }

        }


    }
    const CheckPassword = (e) => {
        e.preventDefault();

        if(e.target.value.length<8){
            setdata({...data,msg_pwd: 'الرقم السري غير مطابق',etat_pwd: false});
            setdata({ ...data, Password: ''});
        }else
        {
            setdata({...data,Password : e.target.value,msg_pwd: '',etat_pwd: true});
        }
    }
    const CheckDPassword = (e) => {
        e.preventDefault();

            if(data.Password!==data.DPassword){
                setdata({...data,msg_dpwd: 'الرقم السري غير مطابق',etat_dpwd: false});
            }else
            {
                setdata({...data,DPassword:e.target.value,msg_dpwd: '',etat_dpwd: true});
            }

    }
    return(
        <form className="d-flex flex-column text-justify" onSubmit={Registration}>

                <div className="col-sm-12">
                    <label  className="form-label text-justify ">الاسم <span className="spanDanger">*</span></label>
                    <input type="text"  required title="إسم المشترك " className={data.msg_firstname!=''? "form-control border-danger" : "form-control" } id="firstName" value={data.firstName} name="firstName"  onChange={onChange} />
                    <small className="text-danger">{data.msg_firstname}</small>
                </div>
                <div className="col-sm-12">
                    <label  className="form-label">اللقب <span className="spanDanger">*</span></label>
                    <input type="text" title="لقب المشترك" className={data.msg_lastname!=''? "form-control border-danger" : "form-control" } id="lastName"  name="lastName" value={data.lastName} required  onChange={onChange}  />
                    <small className="text-danger">{data.msg_firstname}</small>
                </div>
                <div className="col-sm-12">
                    <label className="form-label text-justify ">رقم بطاقة التعريف الوطنية  <span className="spanDanger">*</span> </label>
                    <input type="text" title="رقم بطاقة التعريف" className="form-control" pattern="[0-9]*" id="Cin" name="Cin"  onChange={onChange} onBlur={CheckCin} value={data.Cin}  required />
                    <small className="text-danger" id="msg_cin">{data.msg_cin}    </small>
                </div>
                <div className="col-sm-12">
                <label  className="form-label text-justify ">رقم الهاتف الجوال  <span className="spanDanger">*</span></label>
                <input type="text" title="رقم الهاتف الجوال" className="form-control" pattern="[0-9]*" id="Gsm" name="Gsm" value={data.Gsm} placeholder="XXXXXXXX" onChange={onChange} onBlur={CheckPhone} required />
                    <small className="text-danger" id="msg_gsm">{data.msg_gsm}    </small>
            </div>
                <div className="col-sm-12">
                <label className="form-label text-justify "> كلمة العبور <span className="spanDanger">*</span></label>
                <input type="password" title="كلمة السر" className="form-control" id="Password" onBlur={CheckPassword} name="Password" value={data.Password}  onChange={onChange} required />

                    <small className="text-danger" id="msg_pwd" >{data.msg_pwd}</small>
            </div>
                <div className="col-sm-12">
                <label className="form-label text-justify ">تاكيد كلمة العبور<span className="spanDanger">*</span></label>
                <input type="password" title="تاكيد كلمة السر" className="form-control" id="DPassword" onBlur={CheckDPassword} name="DPassword" value={data.DPassword}  onChange={onChange} required />
                <small className="text-danger">{data.msg_dpwd}</small>
                </div>
                <div className="col-sm-12">
                    <label  className="form-label text-justify ">العنوان الإلكتروني <span className="spanDanger">*</span></label>
                    <input type="email" title="العنوان الإلكتروني " className="form-control" id="Email"name="Email"  value={data.Email} onChange={onChange} onBlur={CheckEmail} required />
                    <small className="text-danger" id="msg_email">{data.msg_email}    </small>
                </div>



                <hr />
            {!data.Save &&
            <Valider/>
            }
            {data.Save &&
            <div className="bg-info">
                <h3 className="text-white font-weight-bold text-center">
                    لقد تم تفعيل حسابكم بنجاح
                </h3>
            </div>
            }

        </form>
    );
}

function Valider() {
    return(
        <div className="row">
                <div className="left margin">
                    <button  className="btn btn-success"   >فتح حساب</button>
                </div>
                <div className="left margin">
                    <MenuLinkCondition to="/" classname="btn btn-info" label="أمتلك حساب شخصي" />
                 </div>

        </div>
    );
}


function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
export default Register;
