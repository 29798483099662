import React,{ useState } from 'react'
import {URL_DASHBOARD} from "../config";


function Pdfview(props) {


    return (

        <div>
            <embed src={URL_DASHBOARD+'/'+ props.match.params.viewpdf} width="100%" height="600"
                   type="application/pdf" />

        </div>
    );


}

export default Pdfview
