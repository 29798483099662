import React , { useState} from 'react';
import Header from "./Header";
import {Link,useHistory,useParams } from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import Footer from "./Footer";
class  CheckRegister extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: null,
            etat:'',
            cin:null,
        };

    }
    componentDidMount() {
        this.setState({cin:this.props.match.params.cin});
    }
    componentWillUnmount() {


    }

    render() {
        return(
            <div className="App">
                <main>
                    <Header />

                    <RegisterUser />
                    <Footer />
                </main>


            </div>

        );
    }
}
function RegisterUser(props) {
    let history = useHistory();
    const cin=useParams();
    const [data, setdata] = useState({ Cin: cin.cin,Code:'',user:{},is_connected: null})
    const apiUrl = API_BASE_URL+'/check-register';
    const CheckRegistration = (e) => {
        localStorage.clear();
        sessionStorage.clear();
        e.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.append('Cin', data.Cin);
        bodyFormData.append('Code', data.Code);
        let axiosConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        axios.post(apiUrl, bodyFormData,axiosConfig)
            .then((result) => {
                if(result.data && result.data.user && result.data.user.etat===1) {
                    // setdata({...data,user:result.user});
                    // localStorage.setItem('token', result.data.token);
                    // localStorage.setItem('is_connected','true');
                    // localStorage.setItem('user',JSON.stringify(result.data.user));
                    // setdata({...data,is_connected:localStorage.getItem('is_connected')});
                    history.push('/');
                }
                else{
                    setdata({...data,user:{}});
                    setdata({...data,is_connected:'no-checked'});
                    // alert('الرجاء تثبت فالكود');
                }

            });
    }
    const onChange = (e) => {
        e.persist();
        setdata({ ...data, [e.target.name]: e.target.value });
    }
    return(
        <section className="  text-center container " >

            <div className="col-lg-6 mx-auto">
                <h3 className="font-weight-light font-weight-bold">تفعيل حساب</h3>
                <label className="text-danger">
                    لقد تم تفعيل حسابكم بنجاح الرجاء إدخال رقم بطاقة التعريف الوطنية و كلمة العبور
                </label>
                <div className="bg-warning text-center">
                    <h5 className="text-white" style={{fontSize:"x-large"}}>لقد تم إرسال الرمز عن طريق البريد الإلكتروني<br/> والهاتف الجوال</h5>
                </div>

                {data.is_connected && data.is_connected==='no-checked' &&
                <Alert variant="danger" onClose={() => localStorage.getItem('is_connected')}>
                    <Alert.Heading>تثبت!</Alert.Heading>
                    <p>
                        الرجاء التثبت في الرقم السري                          </p>
                </Alert>
                }
                <form className="d-flex flex-column text-justify" onSubmit={CheckRegistration}>

                        <div className="row g-3">
                            <div className="col-sm-12">
                                <label className="form-label text-justify ">رقم بطاقة التعريف الوطنية  <span className="spanDanger">*</span> </label>
                                <input type="text" className="form-control " id="Cin" name="Cin"  onChange={onChange} value={data.Cin} required />

                            </div>


                        </div>
                        <div className="row g-3">
                            <div className="col-sm-12">
                                <label className="form-label text-justify ">الرقم السري (الهاتف الجوال) <span className="spanDanger">*</span> </label>
                                <input type="text" className="form-control" id="Code" name="Code"  onChange={onChange} value={data.Code} required />

                            </div>


                        </div>

                        <hr />
                         <div className="row">
                                    <div className="left margin">
                                        <button  className="btn btn-success" onClick={CheckRegistration} required >تفعيل</button>
                                    </div>
                                    <div className="left margin">
                                        <MenuLinkCondition to="/" classname="btn btn-info" label="أمتلك حساب شخصي" />
                                    </div>

                                </div>
                </form>
            </div>
        </section>
    );
}

function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
export default CheckRegister;
