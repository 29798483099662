import React from 'react';
import {API_BASE_URL} from "../config";
import {Message} from "semantic-ui-react";
import Footer from "./Footer";
import {Link, useHistory} from "react-router-dom";

import Navph from "../Dashboard/Navph";
class DetailSujet extends React.Component  {

    constructor(props) {
        super(props);

        this.state = {
            sujets:null,
            isLoading: null,
            user: null
        }

    }
    componentDidMount() {
        if(sessionStorage.getItem('is_connected')) {
            this.setState({user: JSON.parse(localStorage.getItem('user'))});
            this.getDetailSujets(this.props.match.params.slug);
        }
    }

    async getDetailSujets(slug) {

        if (! this.state.sujets) {
            try {
                this.setState({ isLoading: true });
                const response = await fetch(API_BASE_URL + '/detail/'+slug, {});
                const sujetList = await response.json();
                this.setState({ sujets: sujetList.data, isLoading: false});

            } catch (err) {
                this.setState({ isLoading: false });
                console.error(err);
            }
        }
    }
    render() {

        return (



            <div className="App">

                <header className="navbar navbar-dark  bg-info ">
                    <a className="text-white font-weight-bold col-md-3 col-lg-2 me-0 px-3 font-weight-bold" > الصيدلية المركزية</a>
                    <a className="navbar-toggler float-left d-md-none collapsed" type="button"
                       data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
                       aria-expanded="false" aria-label="تبديل التنقل">
                        <span className="navbar-toggler-icon"></span>
                    </a>
                    <ul className="navbar-nav px-3 border-right">
                        <li className="nav-item text-nowrap">
                            <LogoutUser />
                        </li>

                    </ul>
                </header>

                <div className="container-fluid">
                    <div className="row">

                        <Navph name="detail" />

                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                            {this.state.isLoading && <Message info  header="تحميل  ..." />}
                            {this.state.sujets &&    this.state.sujets.map((sujet, i) => <Content
                                key = {i} componentData = {sujet}/>)}
                            <Footer />
                        </main>
                        <script>

                        </script>
                    </div>








                </div>



            </div>




        );
    }
}
function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
function LogoutUser(props) {
    let history=useHistory();
    const Logout= (e) => {
        e.preventDefault();
        localStorage.clear();
        history.push('/');
    }
    return(
        <button className="nav-link btn btn-group-sm text-white font-weight-bold" onClick={Logout}>خروج</button>
    );
}
class Content extends React.Component {
    render() {
        return (
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2 text-danger">عنوان الخطة : {this.props.componentData.titre}  </h1>

                </div>

                <div className="row title">

                    <div className="row">
                        <div className="col-md-12 margin">
                            {this.props.componentData.image &&
                            <img src={this.props.componentData.image} width="60%"  className="border margin" />}
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12 text-right">
                            <p >
                                <div dangerouslySetInnerHTML={{ __html: this.props.componentData.description }} />

                            </p>
                        </div>

                    </div>
                </div>

            </main>


        );
    }
}

export default DetailSujet;
