import axios from "axios";
import {API_BASE_URL} from "../../config";
import {GET_SETTING} from '../types'


export const getSetting = () => async dispatch => {

    try{
        await axios.get(API_BASE_URL+ '/getLastConcours')
            .then((result) => {
             //   console.log(result.data);
                dispatch( {
                    type: GET_SETTING,
                    payload: result.data
                })
            });

    }
    catch(e){
        dispatch( {
            type: GET_SETTING,
            payload: []
        })
    }

}


