import {GET_SETTING} from '../types'

const initialState = {
    setting:[],
    is_closed:false,
    is_blocked: false,
}

export default function (state = initialState, action){

    switch(action.type){

        case GET_SETTING:
            let is_closed=false;
            const   getDateDiff=() =>{

                var date1=new Date();
                var date2=new  Date(action.payload.concours_inscrit.date_fininscription);
                var sec = (date2 - date1) / 1000;
                // console.log('Sec:='+sec);
                var n = 24 * 3600;
                if (sec < 0) {
                    is_closed=true;
                  }

            }
            return {
                ...state,
                setting:action.payload,
                is_closed:is_closed

            }
        default: return state
    }

}
