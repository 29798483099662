import React from 'react';
import {API_BASE_URL} from "../config";
import { Message} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";

import { Redirect } from "react-router-dom";
import $ from "jquery";

class Sujet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sujets:null,
            titresujets: null,
            villes:null,
            postes:null,
            codes:null,
            isLoading: null,
            user: {},
            slug:null,
            title: 'عناوين الخطط'
        }
    }
    componentDidMount() {
        this.getSujets();
        this.getTitreSujets();
        if(sessionStorage.getItem('is_connected')){
            this.setState({user:JSON.parse(localStorage.getItem('user'))});
            if(localStorage.getItem('slug'))
                this.setState({slug:localStorage.getItem('slug')});
        }
    }
     Filter=() =>{

            try {
                this.setState({title:'عناوين الخطط '});
                var bodyFormData = new FormData();
                bodyFormData.append('codesujet', $('#codesujet option:selected').val());
                bodyFormData.append('title', $('#title option:selected').val());
                bodyFormData.append('city', $('#city option:selected').val());
                bodyFormData.append('diplome', $('#diplome option:selected').val());
                let axiosConfig = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
               axios.post(API_BASE_URL+'/filtersujet', bodyFormData,axiosConfig)
                    .then((result) => {
                      // console.log(result.data.data);
                        if(result.data.data.length===0)
                            this.setState({title:'لا يوجد خطط حسب البحث '});
                        this.setState({ sujets: result.data.data, isLoading: false});
                    });

            } catch (err) {

                console.error(err);
            }

    }
    async getTitreSujets() {
        if (! this.state.titresujets) {
            try {
                this.setState({ isLoading: true });
                const response = await fetch(API_BASE_URL + '/titresujets', {});
                const sujetList = await response.json();
                this.setState({ titresujets: JSON.parse(JSON.stringify(sujetList.data)), isLoading: false});
                this.setState({ villes: sujetList.villes, isLoading: false});
                this.setState({ postes: sujetList.postes, isLoading: false});
                this.setState({ codes: sujetList.codes, isLoading: false});

            } catch (err) {
                this.setState({ isLoading: false });
                console.error(err);
            }
        }
    }
    async getSujets() {
        if (! this.state.sujets) {
            try {
                this.setState({ isLoading: true });
                const response = await fetch(API_BASE_URL + '/sujet', {});
                const sujetList = await response.json();
                this.setState({ sujets: sujetList.data, isLoading: false});

            } catch (err) {
                this.setState({ isLoading: false });
                console.error(err);
            }
        }
    }
    render() {
        return (


                <div className="row">
                    <div className="form-group col-md-12 bg-white border">
                        <h4 className="text-muted text-uppercase title">البحث عن خطة :</h4>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <label>الرمز :</label>
                                <select className="form-control" id="codesujet" onChange={this.Filter}>
                                    <option></option>
                                    {this.state.codes &&    this.state.codes.map((sujet, i) =>
                                    <option>{sujet.codesujet}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label>الخطة :</label>
                                <select className="form-control" id="title" onChange={this.Filter}>
                                    <option></option>
                                    {this.state.titresujets &&    this.state.titresujets.map((sujet, i) =>
                                        <option value={sujet.id}>{sujet.title}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label>الولاية :</label>
                                <select className="form-control" id="city" onChange={this.Filter}>
                                    <option></option>
                                    {this.state.villes &&    this.state.villes.map((sujet, i) =>
                                        <option>{sujet}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label>الإختصاص :</label>
                                <select className="form-control" id="diplome" onChange={this.Filter}>
                                    <option></option>
                                    {this.state.postes &&    this.state.postes.map((sujet, i) =>
                                        <option>{sujet.description}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="form-group col-md-12">
                        <h4 className="text-muted text-uppercase title">{this.state.title}</h4>

                            <div className="row ">
                                {this.state.isLoading && <Message info  header="تحميل العناوين ..." />}
                                {this.state.sujets &&    this.state.sujets.map((sujet, i) => <Content
                                    key = {i} componentData = {sujet} user={this.state.user}/>)}
                            </div>



                    </div>


                </div>











        );
    }
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            slug:null,
        }

    }

    componentDidMount() {

        if(localStorage.getItem('is_connected')==='true' && localStorage.getItem('is_connected')){
            this.setState({slug:localStorage.getItem('slug')});

        }


    }

     CheckAvis = (e) => {
        e.preventDefault();

        var bodyFormData = new FormData();
        bodyFormData.append('Token', this.props.user.token);
        bodyFormData.append('Slug', this.props.componentData.slug );
        let axiosConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        console.log('body'+bodyFormData)
        axios.post(API_BASE_URL+'/check-avis', bodyFormData,axiosConfig)
            .then((result) => {

                if(result.data && result.data &&  result.data.slug) {
                    localStorage.setItem('slug',result.data.slug);
                    localStorage.setItem('user',JSON.stringify(result.data.user));
                    this.setState({slug:localStorage.getItem('slug')});
                    window.location.reload(false);
                }

            }).catch(error => {
            console.log(error)
        });
    }

    render() {
        if(!this.state.slug){
            return (
                <div className="form-group col-md-4 border bg-light text-black-50 font-weight-bold">
                    <div className="row">
                        <div className="col-md-12">
                            <label className="text-info">الرمز : </label>
                            <span className="text-danger">{this.props.componentData.codesujet}</span>
                        </div>

                        <div className="col-md-12">
                            <label className="text-info">الخطة :</label>
                            {this.props.componentData.titre}
                        </div>
                        <div className="col-md-12">
                            <label className="text-info">مكان العمل :</label>
                            {!this.props.componentData.deligation && this.props.componentData.ville}
                            {this.props.componentData.deligation && this.props.componentData.deligation}
                        </div>
                        <div className="col-md-12">
                            <label className="text-info">عدد الخطط :</label>
                            {this.props.componentData.nbre}
                        </div>
                        <div className="col-md-12">
                            <label className="text-info"> الإختصاص المطلوب :</label>
                            <small dangerouslySetInnerHTML={{ __html: this.props.componentData.description }} />
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="form-group col-md-6 right">
                                    <MenuLinkCondition to={`/register-condidate/${this.props.componentData.codesujet}/${this.props.componentData.titre}`}  classname="btn btn-group-lg btn-success" label="تسجيل" >

                                    </MenuLinkCondition>
                                </div>
                            </div>

                        </div>
                        </div>


                </div>



            );
        }else{
            return (
                <Redirect to="/dashboard"  />
            );
        }

    }
}


function MenuLinkCondition({ label, to,classname }) {

    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
export default Sujet;
