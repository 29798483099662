import React from "react";
import Navph from "./Navph";
import {Link, useHistory} from "react-router-dom";
import Footer from "../Compenents/Footer";
import Header from "../Compenents/Header";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import {API_BASE_URL, URL_DASHBOARD} from "../config";
import Headerdashboard from "./Headerdashboard";
import Printdocument from "./Printdocument";
import {Document, Page, PDFDownloadLink} from "@react-pdf/renderer";


class Documentprint extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            slug: null,
            codesujet:null,
            resultform:null,
            city:null,
            speciality:null,
            ville:null,
            deligation: null,
            pdflink:null,
            date_impression:null,
            created_at:null
        }

    }
    getFormulaire (){
        let cin =JSON.parse(localStorage.getItem('user')).cin;
        if(cin){
            var bodyFormData = new FormData();
            bodyFormData.append('user_cin', cin);

            let axiosConfig = {
                headers: { 'Content-Type': 'multipart/form-data' }
            };
            axios.post(API_BASE_URL+'/getformulaire', bodyFormData,axiosConfig)
                .then((result) => {
                    if(result.data ) {
                        if(result.data.result && result.data.result!='null'){
                            this.setState({resultform:JSON.parse(JSON.stringify(result.data.result))});

                            this.setState({pdflink:URL_DASHBOARD+'/'+result.data.pdflink});
                        }


                    }


                });
        }

    }
    componentDidMount() {

        if(sessionStorage.getItem('is_connected')){
            this.setState({is_connected:sessionStorage.getItem('is_connected')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});
            this.setState({slug:localStorage.getItem('slug')});
            this.setState({code:localStorage.getItem('code')});
            this.setState({city:localStorage.getItem('city')});
            this.setState({speciality:localStorage.getItem('speciality')});
            this.setState({ville:localStorage.getItem('ville')});
            this.setState({deligation:localStorage.getItem('deligation')});
            this.setState({date_impression:localStorage.getItem('validate_date')});
            this.setState({created_at:localStorage.getItem('created_at')});
            this.getFormulaire();

        }


    }
    render() {
        if(this.state.is_connected==='true' && this.state.slug) {
            return (
                <div className="App">
                  <Headerdashboard />
                    <div className="container-fluid">
                        <div className="row">

                            <Navph name="document" />

                            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                                <div className="col-md-6 ms-sm-auto col-lg-12 px-md-4">
                                    <div
                                        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                        <h1 className="h2 text-danger">إستمارة الترشح </h1>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Printdocument />

                                        </div>

                                    </div>
                                    <div className="row" id="impr" ref={el => (this.componentRef = el)}>

                                            <Documentuser slug={this.state.slug} speciality={this.state.speciality}
                                            code={this.state.code} user={this.state.user} created_at={this.state.created_at}
                                             resultform={this.state.resultform}    date_impression={this.state.date_impression}
                                                          deligation={this.state.deligation} vilee={this.state.ville}
                                                          city={this.state.city}
                                            />

                                     </div>



                                </div>

                            </main>

                        </div>








                    </div>
                </div>
            )
        }
        if(this.state.is_connected==='true' && !this.state.slug) {
            return (
                <div className="App">
                    <Headerdashboard />
                    <div className="container-fluid">
                        <div className="row">

                            <Navph name="document" />

                            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                                <div className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
                                    <div
                                        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                        <h1 className="h2 text-danger">إستمارة الترشح </h1>

                                    </div>
                                    <div className="row">


                                        <Alert
                                            variant="info" onClose={() => localStorage.getItem('is_connected')} >
                                            <Alert.Heading></Alert.Heading>

                                            <p className="font-italic">
                                                لم يتم إختيار أي خطة حتى الآن
                                            </p>

                                        </Alert>



                                    </div>



                                </div>
                                {/*<Footer />*/}
                            </main>
                            <script>

                            </script>
                        </div>








                    </div>
                </div>
            )
        }
        if(!this.state.is_connected){
            return(
                <div className="App">
                    <main>
                        <Header />
                        <hr />
                        <section className="  text-center container">
                            <div className="col-lg-6 mx-auto">
                                <Redirect />
                                <p>{this.state.is_connected}</p>
                                <div className="row">
                                    <div className="left margin">
                                        <MenuLinkCondition to="/" classname="btn btn-info" label="  تسجيل الدخول " />
                                    </div>


                                </div>
                            </div>
                        </section>
                        <Footer/>
                    </main>
                </div>


            );
        };
    }
}
const Documentuser=(props)=>(
    <Document>
        <Page size="A4" style={{textAlign:"center",fontFamily:"DroidArabicKufiRegular"}}>
            <div>
                <img src={process.env.PUBLIC_URL+"/logo.png"} />
                <h5 style={{borderBottom:"solid 1px #000",padding:'10px'}}>إستمارة  ترشح لمناظرة إنتداب بالملفات وبالإختبارات الشفاهية
                    <br/>بالصيدلية المركزية التونسية</h5>
                <div className="col-md-12 text-center">
                    <h6 >
                        <p><span style={{fontWeight:"bold"}}>مناظرة إنتداب</span> <span >{props.slug}</span>  &ensp;
                            <span style={{fontWeight:"bold"}}>إختصاص</span> <span  > {props.speciality}</span>   </p>
                        <p>
                            <span style={{fontWeight:"bold"}}>رمز الخطة </span> <span >{props.code}</span>
                            <span style={{fontWeight:"bold"}}>   مكان العمل </span>
                            <span >{props.city}</span>
                        </p>
                        <p>
                            <span style={{fontWeight:"bold",fontSize:"18px"}}>   رقم التسجيل </span>
                            <span > {props.user.numero_inscrit}</span>
                        </p>
                        <p>
                            <span style={{fontWeight:"bold",fontSize:"18px"}}>تاريخ التسجيل   </span>
                            <span > { props.created_at}</span>

                        </p>
                        <p>
                            <span style={{fontWeight:"bold",fontSize:"18px"}}>  العدد المسند للملف </span>
                            <span > {props.user.score}</span>
                        </p>

                    </h6>
                </div>
                <div className="col-md-12 text-right " style={{fontSize:"18px"}}>
                    <table className="table "  width="100%" >
                        <tr>
                            <th style={{textAlign:"right"}}>
                                الإسم و اللقب
                            </th>
                            <td style={{textAlign:"right"}}>
                                                                <span >
                                                                    {props.user.firstname} {props.user.lastname}
                                                                </span>
                            </td>

                        </tr>
                        <tr>
                            <th style={{textAlign:"right"}}>
                                رقم بطاقة التعريف الوطنية
                            </th>
                            <td  >
                                <span > {props.user.cin}</span>
                            </td>
                        </tr>
                        <tr>

                            <th style={{textAlign:"right"}}>   تاريخ الولادة </th>
                            <td >
                                                                <span>
                                                                    {props.user.datebirth}
                                                                </span>
                            </td>





                        </tr>
                        <tr>
                            <th style={{textAlign:"right"}}>الجنس </th>

                            <td  style={{textAlign:"right"}}>
                                <span> {props.user.gendre}</span></td>


                        </tr>
                        <tr>
                            <th style={{textAlign:"right"}}>     الحالة المدنية </th>

                            <td  style={{textAlign:"right"}}>
                                <span >{props.user.cituation}</span>
                            </td>


                        </tr>
                        <tr>

                            <th style={{textAlign:"right"}}> العنوان </th>

                            <td style={{textAlign:"right"}}>
                                <span > {props.user.adresse}</span>
                            </td>


                        </tr>
                        <tr>
                            <th style={{textAlign:"right"}}> الترقيم البريدي   </th>

                            <td style={{textAlign:"right"}}>
                                <span >{props.user.codepostal} </span>
                            </td>
                        </tr>
                        <tr>
                            <th style={{textAlign:"right"}}> المعتمدية </th>

                            <td style={{textAlign:"right"}}>
                                <span > {props.deligation}</span>
                            </td>
                            <th style={{textAlign:"right"}}> الولاية</th>

                            <td style={{textAlign:"right"}}>
                                <span > {props.ville}</span>
                            </td>



                        </tr>
                        <tr>
                            <th style={{textAlign:"right"}}> الهاتف </th>

                            <td style={{textAlign:"right"}}>
                                <span >  {props.user.gsm}</span>
                            </td>
                            <th style={{textAlign:"right"}}>البريد الإيلكتروني </th>

                            <td style={{textAlign:"right"}}>
                                <span>{props.user.email}</span>
                            </td>

                        </tr>
                        {props.resultform && props.resultform.map((result, i) =>
                            <tr>
                                <th style={{textAlign:"right"}}>
                                    {result.name}
                                </th>

                                <td style={{textAlign:"right"}} colSpan={3}>
                                    <span > {result.value}</span>
                                </td>

                            </tr>
                        )}
                    </table>
                    <p style={{textAlign:"left"}}>
                        <span style={{fontWeight:"bold",fontSize:"18px"}}>تاريخ آخر تحيين </span>
                        <span style={{fontWeight:"bold"}}> { props.date_impression}</span>

                    </p>
                </div>

            </div>
        </Page>
    </Document>
);
function Redirect(props) {
    let history=useHistory();

    // history.push('/');
    return(
        <Alert
            variant="danger" onClose={() => localStorage.getItem('is_connected')} >
            <Alert.Heading>تحذير!</Alert.Heading>

            <p>
                لا يمكن لك أدخول في هذه المنظومة
            </p>

        </Alert>
    );
}
function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
export default Documentprint;
