import React, {useEffect} from 'react';
import {Link,  useHistory} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Header from "../Compenents/Header";
import Sujet from "../Compenents/Sujet";
import Navph from "./Navph";
import Topdashboard from "./Topdashboard";
import Headerdashboard from "./Headerdashboard";
import Printdocument from "./Printdocument";

import {URL_DASHBOARD} from "../config";
import {connect} from "react-redux";
import {getSetting} from "../sotre/actions/settingAction";

class Suivieconcours extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            setting:null,
            shares:null,

        }

    }
    getLastConcours=() =>{
        try {
            const {setting} = this.props.setting;
            this.setState({setting:setting});
            if (setting.concours_inscrit){
                if(setting.shares && setting.shares.length>0)
                    this.setState({shares: setting.shares});
                console.log(setting.shares);
            }

        } catch (err) {
            console.error(err);
        }
    }
    componentDidMount() {
        this.getLastConcours();
        const {setting} = this.props.setting;
        this.setState({setting:this.props.setting});

    }



    render() {

            return (

                <div className="App">

                    <Headerdashboard />

                    <div className="container-fluid">
                        <div className="row">
                            <Navph name="suivieconcours" />

                            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2 text-danger">الإطلاع على مراحل تقدم المناظرة </h1>

                                </div>

                                 <div className="col-md-12">
                                     <div className="row">
                                         {this.state.shares &&    this.state.shares.map((share, i) =>
                                         <div className="form-group text-center col-md-4  text-black-50 font-weight-bold" >
                                            <div className="col-md-12">
                                                <img src={window.location.origin+'/avis_pct.png'}  alt=" الصيدلية المركزية التونسية" className="App-logo" />
                                            </div>
                                             <div className="col-md-12">
                                                 <Link to={"/pdf/"+share.url_pdf} target="_blank" className="nav-link text-muted">
                                                     <i className="fa fa-hand-o-left"></i>&ensp;
                                                     {share.title}
                                                 </Link>

                                             </div>



                                         </div>
                                         )}
                                     </div>

                                 </div>


                            </main>
                        </div>
                    </div>
                </div>
            )

    }
}
function Redirect(props) {

    return(
        <Alert
            variant="danger" onClose={() => localStorage.getItem('is_connected')} >
            <Alert.Heading>تحذير!</Alert.Heading>

            <p>
                لا يمكن لك أدخول في هذه المنظومة
            </p>

        </Alert>
    );
}
function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
const mapStateToProps  = (state) => ({setting:state.setting})

export default connect(mapStateToProps, {getSetting})(Suivieconcours)
