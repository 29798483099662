import React, {useEffect} from 'react';
import {Link,  useHistory} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Header from "../Compenents/Header";
import Sujet from "../Compenents/Sujet";
import Navph from "./Navph";
import Topdashboard from "./Topdashboard";
import Headerdashboard from "./Headerdashboard";
import Printdocument from "./Printdocument";

import {URL_DASHBOARD} from "../config";
import {connect} from "react-redux";
import {getSetting} from "../sotre/actions/settingAction";

class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            is_connected: null,
            user:{},
            slug: null,
            title:null,
            code: null,
            is_closed:false,
            date_concours:null,
            setting:null,
            msgInscrit:null,

        }

    }
    getTimer =()=>{
        const interval = setInterval(() => {
            if(localStorage.getItem('DateEnd'))
                this.getDateDiff();
        }, 1000);
        return () => clearInterval(interval);
    }
    getDateDiff() {
            //console.log(localStorage.getItem('DateEnd'));
            var date1=new Date();
        var table=localStorage.getItem('DateEnd').split(' ');
        var datetime=table[0].split('-');
        var time=table[1].split(':')
        // console.log(datetime[0]+','+datetime[1]+','+datetime[2]+','+time[0]+','+time[1]+','+time[2]);
        var date2=new  Date(datetime[0],datetime[1]-1,datetime[2],time[0],time[1],time[2]);

            var sec = (date2 - date1) / 1000;
            // console.log('Sec:='+sec);
            var n = 24 * 3600;
            if (sec >=1) {

                var j = Math.floor(sec / n);
                var  h = Math.floor((sec - (j * n)) / 3600);
                var   mn = Math.floor((sec - ((j * n + h * 3600))) / 60);
                var   sec2 = Math.floor(sec - ((j * n + h * 3600 + mn * 60)));
                var text='';
                if(j>1)
                    text=j+'(أيام) ';
                if(j==0)
                    text='';
                if(j==1)
                    text= 'يوم ';
                if(h>0)
                    text+=' '+h+' (ساعة) ';
                if(mn>0)
                    text+=' '+mn+' (دقيقة)';
                text+=' '+sec2+'( ثانية ) ';

                this.setState({timestamp: text});

            }else{

                this.setState({is_closed:true})

            }



    }
    getLastConcours=() =>{
        try {
               const {setting} = this.props.setting;
                this.setState({setting:setting});
                if (setting.concours_inscrit){
                    this.setState({date_concours:setting.concours_inscrit.date_fininscription});
                    localStorage.setItem('DateEnd',setting.concours_inscrit.date_fininscription);
                     this.getDateDiff();
                }

        } catch (err) {
            console.error(err);
        }
    }
    componentDidMount() {
        this.props.getSetting();

        if(sessionStorage.getItem('is_connected')){
            this.setState({is_connected:sessionStorage.getItem('is_connected')});
            this.setState({user:JSON.parse(localStorage.getItem('user'))});
            if(localStorage.getItem('slug')){
                this.setState({slug:localStorage.getItem('slug')});
                this.setState({code:localStorage.getItem('code')});

            }


        }

        this.setState({setting:this.props.setting})
        this.getLastConcours();
        this.getTimer();

        if(localStorage.getItem('resultInscrit') && localStorage.getItem('resultInscrit')==='false'){

            this.setState({msgInscrit:localStorage.getItem('msgInscrit') });
        }
    }

    componentWillUnmount() {
        this.getLastConcours();
        this.getTimer();
    }

    render() {
        if(this.state.is_connected==='true') {
            return (

                <div className="App">

                    <Headerdashboard />

                    <div className="container-fluid">
                        <div className="row">
                            <Navph name="dashboard" />
                            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                                <div className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
                                    <div
                                        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                        <h1 className="h2 text-danger">حساب المترشح</h1>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-4  table-responsive ">
                                            {/*<Tempsconcours  is_closed={this.state.is_closed} date_concours={this.state.date_concours} />*/}
                                            <div className="col-md-12 bg-danger">
                                                {!this.state.is_closed &&
                                                <div>
                                                    <hr />
                                                    <p className="text-white"> الوقت المتبقي للمناظرة :{this.state.timestamp}</p>
                                                </div>

                                                }
                                                {this.state.is_closed &&
                                                <div>
                                                    <hr />
                                                    <p>انتها الوقت المتخصص للتحيين أو تغير الخطة    </p>
                                                </div>

                                                }
                                            </div>
                                            <table className="table table-info">
                                                <tr>
                                                    <th className="thwidth"><label className="text-info">إسم المشترك </label></th>
                                                    <td><span className="text-dark">{this.state.user.lastname}  {this.state.user.firstname}</span></td>
                                                </tr>
                                                <tr>
                                                    <th><label className="text-info">رقم بطاقة تعريف  </label></th>
                                                    <td>  <span className="text-dark"> {this.state.user.cin}</span></td>
                                                </tr>
                                                <tr>
                                                    <th><label className="text-info">  الهاتف  </label></th>
                                                    <td>  <span className="text-dark"> {this.state.user.gsm}</span></td>

                                                </tr>
                                                <tr>
                                                    <th className="thwidth"><label className="text-info">  العنوان الإلكتروني  </label></th>
                                                    <td>  <span className="text-dark"> {this.state.user.email}</span></td>
                                                </tr>

                                            </table>
                                        </div>

                                        <div className="col-md-8  title">

                                            {!this.state.slug &&
                                            <Alert
                                                variant="info" onClose={() => localStorage.getItem('is_connected')} >
                                                <Alert.Heading></Alert.Heading>
                                                {!this.state.msgInscrit &&
                                                <p className="font-italic">
                                                    لم يتم إختيار أي خطة حتى الآن
                                                </p> }
                                                <p>
                                                    {this.state.msgInscrit}
                                                </p>

                                            </Alert>}
                                            {this.state.slug &&
                                                <div>
                                                    <Printdocument />
                                                    <Alert
                                                        variant="success" onClose={() => localStorage.getItem('is_connected')} >
                                                        <Alert.Heading></Alert.Heading>

                                                        <p className="font-italic">
                                                              {this.state.slug &&  'الخطة : '+ this.state.slug}
                                                        </p>
                                                        <p className="font-italic">
                                                             {this.state.user.score && this.state.user.score>0 && "العدد المسند للملف : "+ this.state.user.score}
                                                        </p>
                                                        {!this.state.is_closed &&
                                                        <Link
                                                            to={"/register-condidate/" + this.state.code + "/" + this.state.title}>
                                                            الإطلاع أو تحين
                                                        </Link>
                                                        }
                                                    </Alert>

                                                </div>



                                            }
                                            <Topdashboard is_closed={this.state.is_closed}  />
                                        </div>

                                    </div>

                                    <br />
                                    {!this.state.slug && !this.state.is_closed &&
                                    <div className="row title">

                                        <Sujet/>
                                    </div>
                                    }
                                    {this.state.slug && !this.props.setting.is_closed &&
                                    <div className="row title">

                                    </div>
                                    }
                                </div>
                                {/*<Footer />*/}
                            </main>
                        </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="App">
                    <main>
                        <Header />
                        <hr />
                        <section className="  text-center container">
                            <div className="col-lg-6 mx-auto">
                         <Redirect />
                         <p>{this.state.is_connected}</p>
                        <div className="row">
                            <div className="left margin">
                                <MenuLinkCondition to="/" classname="btn btn-info" label="  تسجيل الدخول " />
                            </div>


                        </div>
                            </div>
                        </section>

                    </main>
                </div>
            );
        };
    }
}
function Redirect(props) {

       return(
           <Alert
               variant="danger" onClose={() => localStorage.getItem('is_connected')} >
               <Alert.Heading>تحذير!</Alert.Heading>

               <p>
                   لا يمكن لك أدخول في هذه المنظومة
               </p>

           </Alert>
       );
}
function MenuLinkCondition({ label, to,classname }) {
    return (

        <Link to={to} className={classname}>{label}</Link>

    );
}
const mapStateToProps  = (state) => ({setting:state.setting})

export default connect(mapStateToProps, {getSetting})(Dashboard)
