import React from 'react';
import {Link, useHistory} from "react-router-dom";


class Headerdashboard extends React.Component{
    render() {
        return(
            <header className="navbar navbar-dark  bg-info ">
                <Link to="/dashboard" className="text-white font-weight-bold col-md-6 col-lg-4 me-0 px-3 font-weight-bold"> <img alt="  الصيدلية المركزية التونسية" style={{width:"30px"}} src={process.env.PUBLIC_URL+"/logo2.png"} />  الصيدلية المركزية التونسية</Link>
                <a className="navbar-toggler float-left d-md-none collapsed" type="button"
                   data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
                   aria-expanded="false" aria-label="تبديل التنقل">
                    <span className="navbar-toggler-icon"></span>
                </a>
                <ul className="navbar-nav px-3 border-right">
                    <li className="nav-item text-nowrap">
                        <LogoutUser />
                    </li>

                </ul>
            </header>
        )
    }
}
function LogoutUser(props) {
    let history=useHistory();
    const Logout= (e) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
    }
    return(
        <button className="nav-link btn btn-group-sm text-white font-weight-bold" onClick={Logout}>خروج</button>
    );
}
export default Headerdashboard;
